
import Vue from 'vue';
import i18n from './plugins/i18n'
import App from './App.vue';
import router from './router';
import nowUiKit from './plugins/now-ui-kit';
import axios from 'axios'
import vueAxios from 'vue-axios'
import ant from 'ant-design-vue';
import HighchartsVue from 'highcharts-vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import VueViewer from 'v-viewer'

// optionally import default styles


Vue.config.productionTip = true;

import 'ant-design-vue/dist/antd.css';
import 'viewerjs/dist/viewer.css'


Vue.use(nowUiKit,ant,vueAxios,axios, HighchartsVue, VueDraggableResizable, VueViewer);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
