<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/bg5.jpg')"
      >
      </parallax>
      <div class="container">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/-TfRhXijm8c" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="button-container">
          <a href="#button" class="btn btn-primary btn-round btn-lg">Follow</a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div>
        <h3 class="title">Araç Hakkında</h3>
        <h5 class="description">
          Bu Arac ile surdurulebilir konut tasarimi/ iyilestirmesi hakkinda dusuk farkindalik duzeyine sahip, ancak karar verme surecinde onemli rol oynayan mimar ve konut sahiplerinin ihtiyac duyduklari bilgiye etkin ve anlasilir bir sekilde ulaşabilmeleri amaclanmistir. Bu amac cercevesinde, hedef karar vericilerin karar verme sureclerini kolaylastiracak, bilgilendirme kapasitesi yüksek, enerji ve maliyet odaklı bir Arac gelistirilmistir. Arac, erken tasarim asamasinda cok cesitli tasarim seceneklerinin (yerlesme ve bina geometrisi, bina kabugu, enerji ve yenilenebilir enerji sistemleri) konut performansi uzerindeki etkilerinin gorsel olarak kesfini ve bilincli kararlarin alimini saglayacak hizli ve karsilastirmali geri bildirimleri, kullanimi ve anlasilmasi kolay (intuitive) bir arayuz araciligiyla sunmaktadir.
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
  }
};
</script>
<style></style>
