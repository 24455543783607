<template>
  <a-layout id="starter">
    <a-layout-content :style="{ background: '#fff', padding: '0 50px' }">
      <a-skeleton :loading="loading" active />
      <div :style="{ padding: '24px', minHeight: '380px' }" v-if="!loading">
          <a-page-header
            style="border: 1px solid rgb(235, 237, 240); margin-bottom: 15px"
            :title="$t('PARETO_PAGE_HEADER_TITLE')"
            @back="() => $router.go(-1)">
            <template slot="extra" >
                <a-breadcrumb style="margin-right: 40px;">
                    <a-breadcrumb-item>{{(this.selectedFormData.selectedObjective === 'newDesign' ? this.$t('SELECTION_STEP_1_NEW_DESIGN'): this.$t('SELECTION_STEP_1_RETROFIT'))}}</a-breadcrumb-item>
                    <a-breadcrumb-item>{{(this.selectedFormData.selectedGeometry === 'detached' ? this.$t('SELECTION_STEP_2_DETACHED') : this.$t('SELECTION_STEP_2_ROW'))}}</a-breadcrumb-item>
                    <a-breadcrumb-item>{{(this.selectedFormData.selectedPlanType === 'square' ? this.$t('SELECTION_STEP_3_SQUARE') : this.$t('SELECTION_STEP_3_RECTANGULAR'))}}</a-breadcrumb-item>
                    <a-breadcrumb-item>
                    {{ $t('PARETO_EXTRA_OTHER_TEXT') }}
                    <a-menu slot="overlay" mode="vertical" :selectable="false">
                        <a-menu-item>
                            {{(this.$t('SELECTION_STEP_4_BUILDING_CONSTRUCTION_PERIOD') + ': ' + this.selectedFormData.buildingConstructionPeriod)}}
                        </a-menu-item>
                        <a-menu-item>
                            {{(this.$t('SELECTION_STEP_4_NUMBER_OF_FLOORS') + ': ' + this.selectedFormData.numberOfStorey.replace('_storey',''))}}
                        </a-menu-item>
                        <a-menu-item>
                            {{(this.$t('SELECTION_STEP_4_ROOF_TYPE') + ': ' + (this.selectedFormData.roofType === 'pitchedRoof' ? this.$t('SELECTION_STEP_4_PITCED') : this.$t('SELECTION_STEP_4_TERRACE')))}}
                        </a-menu-item>
                        <a-menu-item>
                            {{(this.$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO') + ': %' + this.selectedFormData.windowToWallRatio)}}
                        </a-menu-item>
                        <a-menu-item>
                            <span v-if="selectedFormData.LCCAnalysisScenarios == 0">
                                {{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' + this.$t('SELECTION_STEP_4_BASE_CASE_SCENARIO') }}
                            </span>
                            <span v-if="selectedFormData.LCCAnalysisScenarios == 1">{{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' +this.$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_1') }}</span>
                            <span v-if="selectedFormData.LCCAnalysisScenarios == 2">{{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' +this.$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_2') }}</span>
                            <span v-if="selectedFormData.LCCAnalysisScenarios == 3">{{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' +this.$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_3') }}</span>
                        </a-menu-item>
                    </a-menu>
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </template>
          </a-page-header>
        <a-row>
            <a-col :span="2">
                <div style="display: inline-block;height: 400px;">
                    <a-slider vertical range :min="YAxisMin" :max ="YAxisMax" :default-value="[YAxisMin, YAxisMax]" @afterChange="yAxisAfterChange" />
                </div>
            </a-col>
            <a-col :span="22">
                <highcharts :options="chartOptions" ref="chart"></highcharts>
                <a-slider range :min="XAxisMin" :max ="XAxisMax" :default-value="[XAxisMin, XAxisMax]" @afterChange="xAxisAfterChange"/>
            </a-col>
        </a-row>
        <hr>
        <a-row v-if="!loading">
            <a-col :span="24">
                <a-button class="editable-add-btn mb-2" v-on:click="openComparisonModal">
                    {{$t('PARETO_COMPARE_BUTTON_TEXT')}}
                </a-button>
                <a-table :row-selection="rowSelection" :columns="selectedFormData.selectedObjective === 'newDesign' ? columns : retrofitColumns" :data-source="tableData" :rowClassName="rowClassName" :customRow="customRow" bordered>
                    <span slot="primaryEnergyConsumption" v-html="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')" :title="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')"></span>
                    <span slot="lifeCycleCostTL" v-html="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')" :title="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')"></span>
                    <span slot="lifeCycleCost" v-html="$t('PARETO_LIFE_CYCLE_COST_EURO_HTML')" :title="$t('PARETO_LIFE_CYCLE_COST_EURO_HTML')"></span>
                    <span slot="cO2eqEmission" v-html="$t('PARETO_CO2_EMISSION_HTML')" :title="$t('PARETO_CO2_EMISSION_HTML')"></span>
                    <span slot="discountedPaybackPeriod" v-html="$t('PARETO_DISCOUNTED_PAYBACK_PERIOD')" :title="$t('PARETO_DISCOUNTED_PAYBACK_PERIOD')"></span>
                    <span slot="primaryEnergyConsumptionSaving" v-html="$t('PARETO_PE_SAVING')" :title="$t('PARETO_PE_SAVING')"></span>
                    <span slot="lifeCycleCostTLSaving" v-html="$t('PARETO_LCC_SAVING')" :title="$t('PARETO_LCC_SAVING')"></span>
                    <span slot="cO2eqEmissionSaving" v-html="$t('PARETO_CO2_SAVING')" :title="$t('PARETO_CO2_SAVING')"></span>
                </a-table>
            </a-col>
        </a-row>
      </div>
      <div id="modal" style="width:900px">
            <a-modal v-model="modalShow" :closable="false" :dialog-style="{ top: '2px' }" :cancelText="$t('CancelButtonTxt')" :ok-button-props="{ props: { disabled: true }, style: { display: 'none' } }" size="large" width="100">
                <a-tabs default-active-key="1">
                    <a-tab-pane key="1" :tab="$t('PARETO_COMPARE_MODAL_ENERGY_PERFORMANCE')">
                        <a-table :columns="selectedFormData.selectedObjective === 'newDesign' ? energyPerformanceColumns : energyPerformanceColumnsForRetrofit" :data-source="selectedRows" :rowClassName="rowClassName" bordered size="small">
                            <span slot="heating" v-html="$t('PARETO_HEATING')" :title="$t('PARETO_HEATING')"></span>
                            <span slot="cooling" v-html="$t('PARETO_COOLING')" :title="$t('PARETO_COOLING')"></span>
                            <span slot="lighting" v-html="$t('PARETO_LIGHTING')" :title="$t('PARETO_LIGHTING')"></span>
                            <span slot="domesticHotWater" v-html="$t('PARETO_HOT_WATER')" :title="$t('PARETO_HOT_WATER')"></span>
                            <span slot="finalEnergyConsumption" v-html="$t('PARETO_FINAL_ENERGY_CONSUMPTION')" :title="$t('PARETO_FINAL_ENERGY_CONSUMPTION')"></span>
                            <span slot="primaryEnergyConsumption" v-html="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')" :title="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')"></span>
                            <span slot="cO2eqEmission" v-html="$t('PARETO_CO2_EMISSION_HTML')" :title="$t('PARETO_CO2_EMISSION_HTML')"></span>
                            <span slot="pVshare" v-html="$t('PARETO_PV_SHARE')" :title="$t('PARETO_PV_SHARE')"></span>
                            <span slot="primaryEnergyConsumptionSaving" v-html="$t('PARETO_PE_SAVING')" :title="$t('PARETO_PE_SAVING')"></span>
                            <span slot="cO2eqEmissionSaving" v-html="$t('PARETO_CO2_SAVING')" :title="$t('PARETO_CO2_SAVING')"></span>
                        </a-table>
                    </a-tab-pane>
                    <a-tab-pane key="2" :tab="$t('PARETO_COMPARE_MODAL_ECONOMIC_PERFORMANCE')" force-render>
                        <a-table :columns="selectedFormData.selectedObjective === 'newDesign' ? economicPerformanceColumns : economicPerformanceColumnsForRetrofit" :data-source="selectedRows" :rowClassName="rowClassName" bordered size="small">
                            <template slot="footer">
                                {{$t('EXCHANGE_RATE')}}
                            </template>
                            <span slot="initialCostTL" v-html="$t('PARETO_INITIAL_COST_HTML')" :title="$t('PARETO_INITIAL_COST_HTML')"></span>
                            <span slot="annualMaintenanceCostTL" v-html="$t('PARETO_ANNUAL_MAINTENANCE_COST')" :title="$t('PARETO_ANNUAL_MAINTENANCE_COST')"></span>
                            <span slot="annualCostofNaturalGasConsumptionTL" v-html="$t('PARETO_ANNUAL_COST_OF_NATURAL_GAS_CONSUMPTION')" :title="$t('PARETO_ANNUAL_COST_OF_NATURAL_GAS_CONSUMPTION')"></span>
                            <span slot="annualCostofElectriticyConsumptionTL" v-html="$t('PARETO_ANNUAL_COST_OF_ELECTRICITY_GAS_CONSUMPTION')" :title="$t('PARETO_ANNUAL_COST_OF_ELECTRICITY_GAS_CONSUMPTION')"></span>
                            <span slot="totalOperationCostTL" v-html="$t('PARETO_TOTAL_OPERATIONAL_COST')" :title="$t('PARETO_TOTAL_OPERATIONAL_COST')"></span>
                            <span slot="lifeCycleCostTL" v-html="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')" :title="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')"></span>
                            <span slot="lifeCycleCostTLSaving" v-html="$t('PARETO_LIFE_CYCLE_COST_SAVING')" :title="$t('PARETO_LIFE_CYCLE_COST_SAVING')"></span>
                            <span slot="discountedPaybackPeriod" v-html="$t('PARETO_DISCOUNTED_PAYBACK_PERIOD')" :title="$t('PARETO_DISCOUNTED_PAYBACK_PERIOD')"></span>
                        </a-table>
                    </a-tab-pane>
                    <a-tab-pane key="3" :tab="$t('PARETO_COMPARE_MODAL_DECISION_VARIABLES')">
                        <a-table :columns="configurationsColumns" :data-source="selectedRows" bordered size="small" :rowClassName="rowClassName">
                            <span slot="renewableEnergySystem" v-html="$t('PARETO_CONFIGURATION_COLUMNS_RENEWABLE_ENERGY_SYSTEM')" :title="$t('PARETO_CONFIGURATION_COLUMNS_RENEWABLE_ENERGY_SYSTEM')"></span>
                            <span slot="configurationColumnsEnergySystem" v-html="$t('PARETO_CONFIGURATION_COLUMNS_ENERGY_SYSTEM')" :title="$t('PARETO_CONFIGURATION_COLUMNS_ENERGY_SYSTEM')"></span>
                            <span slot="configurationColumntBuildingEnvolope" v-html="$t('PARETO_CONFIGURATION_COLUMNS_BUILDING_ENVOLOPE')" :title="$t('PARETO_CONFIGURATION_COLUMNS_BUILDING_ENVOLOPE')"></span>
                            
                            <span slot="exteriorWall" v-html="$t('PARETO_EXTERIOR_WALL_CORE_MATERIAL')" :title="$t('PARETO_EXTERIOR_WALL_CORE_MATERIAL')"></span>
                            <span slot="exteriorWallThermalInsulationType" v-html="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_TYPE')" :title="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_TYPE')"></span>
                            <span slot="exteriorWallThermalInsulationThickness" v-html="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_THICKNESS')"></span>
                            <span slot="roofThermalInsulationType" v-html="$t('PARETO_ROOF_THERMAL_INSULATION_TYPE')" :title="$t('PARETO_ROOF_THERMAL_INSULATION_TYPE')"></span>
                            <span slot="roofThermalInsulationThickness" v-html="$t('PARETO_ROOF_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_ROOF_THERMAL_INSULATION_THICKNESS')"></span>
                            <span slot="groundFloorThermalInsulationThickness" v-html="$t('PARETO_GROUND_FLOOR_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_GROUND_FLOOR_THERMAL_INSULATION_THICKNESS')"></span>
                            <span slot="glazingType" v-html="$t('PARETO_GLAZING_TYPE')" :title="$t('PARETO_GLAZING_TYPE')"></span>
                            <span slot="solarContolElements" v-html="$t('PARETO_SOLAR_CONTROL_SYSTEM_TYPE')" :title="$t('PARETO_SOLAR_CONTROL_SYSTEM_TYPE')"></span>

                            <span slot="heatingSystem" v-html="$t('PARETO_HEATING_SYSTEM')" :title="$t('PARETO_HEATING_SYSTEM')"></span>
                            <span slot="coolingSystem" v-html="$t('PARETO_COOLING_SYSTEM')" :title="$t('PARETO_COOLING_SYSTEM')"></span>
                            <span slot="heatingCoolingSystem" v-html="$t('PARETO_HEATING_COOLING_SYSTEM')" :title="$t('PARETO_HEATING_COOLING_SYSTEM')"></span>
                            <span slot="domesticHotWaterSystem" v-html="$t('PARETO_HOT_WATER_SYSTEM')" :title="$t('PARETO_HOT_WATER_SYSTEM')"></span>

                            <span slot="photovoltaicSystem" v-html="$t('PARETO_PHOTOVOLTAIC_SYSTEM')" :title="$t('PARETO_PHOTOVOLTAIC_SYSTEM')"></span>


                        </a-table>
                    </a-tab-pane>
                </a-tabs>
            </a-modal>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import { Layout, Row, Col, Table, Slider, Skeleton, Button, Modal, Tabs, PageHeader, Breadcrumb, Menu } from 'ant-design-vue';
import apiPareto from '../api/pareto'
import {Chart} from 'highcharts-vue'
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import offlineExporting from "highcharts/modules/offline-exporting";
import message from "ant-design-vue/lib/message";

stockInit(Highcharts);
exportingInit(Highcharts);
offlineExporting(Highcharts);
export default {
    components: {
        'a-layout': Layout,
        'a-layout-content': Layout.Content,
        'a-row': Row,
        'a-col': Col,
        'a-table': Table,
        'a-slider': Slider,
        'a-skeleton': Skeleton,
        'a-button': Button,
        highcharts: Chart,
        'a-modal': Modal,
        'a-tabs': Tabs,
        'a-tab-pane': Tabs.TabPane,
        'a-page-header': PageHeader,
        'a-breadcrumb': Breadcrumb,
        'a-breadcrumb-item': Breadcrumb.Item,
        'a-menu': Menu,
        'a-menu-item': Menu.Item
    },
    data () {
        var self = this;
        return {
            selectedFormData: {},
            tableData: [] ,
            columns: [
                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption', width: 100,ellipsis: true, slots: {title:'primaryEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL', width: 100, slots: {title:'lifeCycleCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCost', key: 'lifeCycleCost', width: 100, slots: {title:'lifeCycleCost'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cO2eqEmission', key: 'cO2eqEmission', width: 100, slots: {title:'cO2eqEmission'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
            ],
            retrofitColumns: [
                { dataIndex: 'primaryEnergyConsumptionForRetrofit', key: 'primaryEnergyConsumptionForRetrofit', width: 100, slots: {title:'primaryEnergyConsumption'}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTLForRetrofit', key: 'lifeCycleCostTLForRetrofit', width: 100, slots: {title:'lifeCycleCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCost', key: 'lifeCycleCost', width: 100, slots: {title:'lifeCycleCost'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cO2eqEmissionForRetrofit', key: 'cO2eqEmissionForRetrofit', width: 100, slots: {title:'cO2eqEmission'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption', width: 100, slots: {title:'primaryEnergyConsumptionSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL', width: 100, slots: {title:'lifeCycleCostTLSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cO2eqEmission', key: 'cO2eqEmission', width: 100, slots: {title:'cO2eqEmissionSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'discountedPaybackPeriod', key: 'discountedPaybackPeriod', width: 100, slots: {title:'discountedPaybackPeriod'}, customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                { title: 'Id', dataIndex: 'caseNo', key: 'caseNo', defaultSortOrder: 'descend',width: 0, sorter: (a, b) => a.caseNo - b.caseNo, className: 'd-none' },
            ],
            energyPerformanceColumns: [
                { dataIndex: 'heating', key: 'heating',width: 100, slots: {title:'heating'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cooling', key: 'cooling',width: 100, slots: {title:'cooling'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lighting', key: 'lighting',width: 100, slots: {title:'lighting'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'domesticHotWater', key: 'domesticHotWater',width: 100, slots: {title:'domesticHotWater'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'finalEnergyConsumption', key: 'finalEnergyConsumption',width: 100, slots: {title:'finalEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption',width: 100, slots: {title:'primaryEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cO2eqEmission', key: 'cO2eqEmission',width: 100, slots: {title:'cO2eqEmission'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'pVshare', key: 'pVshare',width: 100, slots: {title:'pVshare'} }
            ],
            energyPerformanceColumnsForRetrofit: [
                { dataIndex: 'heating', key: 'heating',width: 100, slots: {title:'heating'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cooling', key: 'cooling',width: 100, slots: {title:'cooling'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lighting', key: 'lighting',width: 100, slots: {title:'lighting'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'domesticHotWater', key: 'domesticHotWater',width: 100,slots: {title:'domesticHotWater'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'finalEnergyConsumption', key: 'finalEnergyConsumption',width: 100, slots: {title:'finalEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumptionForRetrofit', key: 'primaryEnergyConsumptionForRetrofit',width: 100,slots: {title:'primaryEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true  },
                { dataIndex: 'cO2eqEmissionForRetrofit', key: 'cO2eqEmissionForRetrofit',width: 100, slots: {title:'cO2eqEmission'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption',width: 100, slots: {title:'primaryEnergyConsumptionSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cO2eqEmission', key: 'cO2eqEmission',width: 100, slots: {title:'cO2eqEmissionSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'pVshare', key: 'pVshare',width: 100, slots: {title:'pVshare'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
            ],
            economicPerformanceColumns: [
                { dataIndex: 'initialCostTL', key: 'initialCostTL',width: 100, slots: {title:'initialCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualMaintenanceCostTL', key: 'annualMaintenanceCostTL',width: 100, slots: {title:'annualMaintenanceCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualCostofNaturalGasConsumptionTL', key: 'annualCostofNaturalGasConsumptionTL',width: 100, slots: {title:'annualCostofNaturalGasConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualCostofElectriticyConsumptionTL', key: 'annualCostofElectriticyConsumptionTL',width: 100, slots: {title:'annualCostofElectriticyConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'totalOperationCostTL', key: 'totalOperationCostTL',width: 100, slots: {title:'totalOperationCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL',width: 100, slots: {title:'lifeCycleCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true }
            ],
            economicPerformanceColumnsForRetrofit: [
                { dataIndex: 'initialCostTL', key: 'initialCostTL',width: 100,slots: {title:'initialCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualMaintenanceCostTL', key: 'annualMaintenanceCostTL',width: 100,slots: {title:'annualMaintenanceCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualCostofNaturalGasConsumptionTL', key: 'annualCostofNaturalGasConsumptionTL',width: 100,slots: {title:'annualCostofNaturalGasConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualCostofElectriticyConsumptionTL', key: 'annualCostofElectriticyConsumptionTL',width: 100,slots: {title:'annualCostofElectriticyConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'totalOperationCostTL', key: 'totalOperationCostTL',width: 100,slots: {title:'totalOperationCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTLForRetrofit', key: 'lifeCycleCostTLForRetrofit',width: 100, slots: {title:'lifeCycleCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL',width: 100, slots: {title:'lifeCycleCostTLSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'discountedPaybackPeriod', key: 'discountedPaybackPeriod',width: 100, slots: {title:'discountedPaybackPeriod'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
            ],
            configurationsColumns:[
                {
                    slots: { title:'configurationColumntBuildingEnvolope' },
                    width: 100,
                    ellipsis: true,
                    children: [
                        { slots: { title:'exteriorWall' }, dataIndex: 'exteriorWall', key: 'exteriorWall', width: 100, customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'exteriorWallThermalInsulationType' }, dataIndex: 'exteriorWallThermalInsulationType', key: 'exteriorWallThermalInsulationType', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                        { slots: { title:'exteriorWallThermalInsulationThickness' }, dataIndex: 'exteriorWallThermalInsulationThickness', key: 'exteriorWallThermalInsulationThickness', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                        { slots: { title:'roofThermalInsulationType' }, dataIndex: 'roofThermalInsulationType', key: 'roofThermalInsulationType', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'roofThermalInsulationThickness' }, dataIndex: 'roofThermalInsulationThickness', key: 'roofThermalInsulationThickness', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'groundFloorThermalInsulationThickness' }, dataIndex: 'groundFloorThermalInsulationThickness', key: 'groundFloorThermalInsulationThickness', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'glazingType' }, dataIndex: 'glazingType', key: 'glazingType', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                        { slots: { title:'solarContolElements' }, dataIndex: 'solarContolElements', key: 'solarContolElements', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                    ]
                },
                {
                    slots: {title:'configurationColumnsEnergySystem'},
                    width: 100,
                    ellipsis: true,
                    children: [
                        { slots: { title:'heatingSystem' }, dataIndex: 'heatingSystem', key: 'heatingSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'coolingSystem' }, dataIndex: 'coolingSystem', key: 'coolingSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'heatingCoolingSystem' }, dataIndex: 'heatingCoolingSystem', key: 'heatingCoolingSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'domesticHotWaterSystem' }, dataIndex: 'domesticHotWaterSystem', key: 'domesticHotWaterSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                    ]
                },
                {
                    slots: {title:'renewableEnergySystem'},
                    width: 100,
                    ellipsis: true,
                    children: [
                        { slots: { title:'photovoltaicSystem' }, dataIndex: 'photovoltaicSystem', key: 'photovoltaicSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text},ellipsis: true }
                    ]
                }
            ],
            YAxisMin: null,
            YAxisMax: null,
            XAxisMin: null,
            XAxisMax: null,
            chartOptions: {
                chart: {
                    type: 'scatter',
                    zoomType: 'xy',
                    events: {
                        load() {
                            this.series[0].chart.setTitle({ text: self.$t('SELECTION_PARETO_BTN_TXT') })
                            if (self.selectedFormData.selectedObjective === 'newDesign') {
                                this.series[0].xAxis.setTitle({text: self.$t('PARETO_PRIMARY_ENERGY_CONSUMPTION')})
                                this.series[0].yAxis.setTitle({text: self.$t('PARETO_LIFE_CYCLE_COST_TL')})
                            } else {
                                this.series[0].xAxis.setTitle({text: self.$t('PARETO_PRIMARY_ENERGY_SAVING')})
                                this.series[0].yAxis.setTitle({text: self.$t('PARETO_LIFE_CYCLE_COST_SAVING')})
                                  
                            }
                            // Base Scenario seçili olması buradan ayarlanmalı
                            // this.series[0].points[0].select()
                        },
                        click(){
                            var points = this.getSelectedPoints();
                            points.forEach((p, i) => {
                                    p.select(false);
                            })
                            // self.loadPage()
                        } 
                    }
                },
                // title: {
                //     text: '$t("PARETO_PAGE_HEADER_TITLE")',
                // },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    verticalAlign: 'bottom',
                    x: 100,
                    y: 70,
                    floating: true,
                    backgroundColor: 'white',
                    borderWidth: 1
                },
                plotOptions: {
                    scatter: {
                        marker: {
                            radius: 5,
                            states: {
                                hover: {
                                    enabled: true,
                                    lineColor: 'rgb(100,100,100)'
                                }
                            }
                        },
                        states: {
                            hover: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x}, {point.y}'
                        }
                    },
                    series: {
                        allowPointSelect: true,
                        point: {
                            events: {
                                select: function(e) {
                                    var getSelectedPoints = this.series.chart.getSelectedPoints();
                                    let selectedPoints = getSelectedPoints.map(item => {
                                            const container = {};
                                            container.primaryEnergyConsumption = item.x;
                                            container.lifeCycleCostTL = item.y;
                                            return container;
                                        });
                                        self.pointSelection(selectedPoints)
                                },
                                unselect: function(e) {
                                    var getSelectedPoints = this.series.chart.getSelectedPoints();
                                    let selectedPoints = getSelectedPoints.map(item => {
                                            const container = {};
                                            container.primaryEnergyConsumption = item.x;
                                            container.lifeCycleCostTL = item.y;
                                            return container;
                                        });
                                    self.pointSelection(selectedPoints)
                                }
                            }
                        }
                    }
                },
                series: [],
            },
            loading: true,
            modalShow: false,
            selectedRows: [],
        }
    },

    methods: {
        rowClassName(record,index) {
            let className = "";
            if (record.key === 147457 || record.key === 147458 || record.key === 147459) className = "bg-info";
            return className;
        },
        customRow(record, index) {
            return {
                on: {
                mouseenter: () => {
                    if (record.key === 147457 || record.key === 147458 || record.key === 147459) {
                        message.info(this.$t('PARETO_TABLE_ROW_BASE_CASE_INFO_TXT'));
                    }
                    },
                },
            };
        },
        yAxisAfterChange(value){
            this.$refs.chart.chart.showLoading();
            apiPareto.GetAllParetoSolutions(this.selectedFormData).then(r => {
                var baseCases = r.data.paretoSolutionsOutput.filter(x=> x.caseNo === 147457 || x.caseNo === 147458 || x.caseNo === 147459)
                this.tableData = r.data.paretoSolutionsOutput.filter(x => x.lifeCycleCostTL > value[0] && x.lifeCycleCostTL < value[1])
                this.tableData.shift()
                if(this.selectedFormData.selectedObjective === 'retrofit') {
                    this.tableData.push(baseCases[0])
                }
                this.chartOptions.series = [{name: "Pareto Optimal Solutions", color: "#71C7FF", data: r.data.chartData.filter(x => x[1] > value[0] && x[1] < value[1] )}]
            })
            this.$refs.chart.chart.hideLoading()
        },
        xAxisAfterChange(value){
            this.$refs.chart.chart.showLoading();
            apiPareto.GetAllParetoSolutions(this.selectedFormData).then(r => {
                var baseCases = r.data.paretoSolutionsOutput.filter(x=> x.caseNo === 147457 || x.caseNo === 147458 || x.caseNo === 147459)
                this.tableData = r.data.paretoSolutionsOutput.filter(x => x.primaryEnergyConsumption > value[0] && x.primaryEnergyConsumption < value[1])
                this.tableData.shift()
                if(this.selectedFormData.selectedObjective === 'retrofit') {
                    this.tableData.push(baseCases[0])
                }
                this.chartOptions.series = [{name: "Pareto Optimal Solutions", color: "#71C7FF", data: r.data.chartData.filter(x => x[0] > value[0] && x[0] < value[1] )}]
            })
            this.$refs.chart.chart.hideLoading()
        },
        pointSelection(values, e) {
            apiPareto.GetAllParetoSolutions(this.selectedFormData).then(r => {
                if (values.length > 0) {
                    var baseCases = r.data.paretoSolutionsOutput.filter(x=> x.caseNo === 147457 || x.caseNo === 147458 || x.caseNo === 147459)
                    this.tableData = r.data.paretoSolutionsOutput.filter(function(item) {
                        for (var key in values) {
                            if (item.lifeCycleCostTL === values[key].lifeCycleCostTL && item.primaryEnergyConsumption === values[key].primaryEnergyConsumption)
                                return true;
                        }
                        return false;
                    });
                    if (baseCases[0] !== undefined) {
                        this.tableData.push(baseCases[0])
                    }
                } else {
                    this.loadPage()
                }
            })
        },
        openComparisonModal(e) {
            this.modalShow = true
        },
        loadPage() {
            this.$nextTick(function () {
                var retrievedObject = localStorage.getItem('form');
                this.selectedFormData = JSON.parse(retrievedObject)
                apiPareto.GetAllParetoSolutions(this.selectedFormData).then(r => {
                    this.chartOptions.series = [{name: "Pareto Optimal Solutions", color: "#71C7FF", data: r.data.chartData}]
                    this.tableData = r.data.paretoSolutionsOutput
                    this.YAxisMin = r.data.yAxisMin
                    this.YAxisMax = r.data.yAxisMax
                    this.XAxisMin = r.data.xAxisMin
                    this.XAxisMax = r.data.xAxisMax
                    this.loading = false;
                })
            })
        }
    },
    mounted() {
        this.loadPage()
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRows = selectedRows
                }
            };
        }
    }
    };
</script>
<style>
#starter {
  min-height: calc(100vh - 76px);
}
.apexcharts-menu-item.exportCSV {
  display: none;
}
</style>
