/* eslint-disable no-debugger */
import http from '../utils/http'

export default {
    GetAllParetoSolutions (data) {
        data.LocalLang = localStorage.getItem("lang");
        return http.post(process.env.VUE_APP_API + 'chart', data)
    },
    GetAllDesignSpace (data) {
        data.LocalLang = localStorage.getItem("lang");
        return http.post(process.env.VUE_APP_API + 'chart/getAllDesignSpace', data)
    },
    FilterAllDesignSpace(data) {
        data.LocalLang = localStorage.getItem("lang");
        return http.post(process.env.VUE_APP_API + 'chart/filterAllDesignSpace', data)

    },
    SendContact(data) {
        return http.post(process.env.VUE_APP_API + 'chart/sendContact', data)

    }
}