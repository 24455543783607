<template>
  <navbar
    position="fixed"
    type="info"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <!-- <router-link v-popover:popover1 class="navbar-brand" to="/">
        
      </router-link> -->
      <img src="img/logo.png" style="height: 60px; width: auto;" alt="">
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link to="/selection" class="nav-link btn btn-info">
          <i class="now-ui-icons business_chart-bar-32"></i>
          <p class="ml-1">{{ $t('HOME_LAUNCH_BUTTON_TITLE') }}</p>
        </router-link>
        <!-- <a href="#/selection" class="nav-link btn btn-neutral">
          <i class="now-ui-icons business_chart-bar-32"></i>
          <p class="ml-1">Başla</p>
        </a> -->
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
