<template>
  <div class="section" id="starter">
      <div class="container">
        <Steps :current="current">
            <Step v-for="item in steps" :key="item.title" :title="item.title">
              <span slot="description">{{item.description}}</span>
            </Step>
        </Steps>
          <div class="steps-content" v-if="current == 0">
            <a-divider>{{ $t('SELECTION_STEP_1_TITLE') }}</a-divider>
            <a-radio-group name="selectedObjective" v-model="selectedObjective" @change="onChangeSelectedObjective">
              <a-row type="flex" justify="center">
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <label>
                    <a-radio :value="'newDesign'" class="card-input-element"></a-radio>
                    <a-card class="card-input" hoverable style="width: 220px; margin:12px;">
                        <img
                          slot="cover"
                          alt="example"
                          src="img/new_design.jpg"
                        />
                        <a-card-meta :title="$t('SELECTION_STEP_1_NEW_DESIGN')">
                          <template slot="description">
                            {{ $t('SELECTION_STEP_1_NEW_DESIGN_DESC') }}
                          </template>
                        </a-card-meta>
                      </a-card>
                  </label>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <label>
                    <a-radio :value="'retrofit'" class="card-input-element"></a-radio>
                    <a-card class="card-input" hoverable style="width: 220px; margin:12px;">
                        <img
                          slot="cover"
                          alt="example"
                          src="img/retrofit.jpg"
                        />
                        <a-card-meta :title="$t('SELECTION_STEP_1_RETROFIT')" :style="{marginBottom: localLang === 'en' ? '18px' : ''}">
                            <template slot="description" >
                              <div>
                                {{ $t('SELECTION_STEP_1_RETROFIT_DESC') }}
                              </div>
                            </template>
                        </a-card-meta>
                      </a-card>
                  </label>
                </a-col>
              </a-row>
            </a-radio-group>
          </div>
          <div class="steps-content" v-if="current == 1">
            <a-divider>{{ $t('SELECTION_STEP_2_TITLE') }}</a-divider>
            <a-radio-group name="selectedGeometry" v-model="selectedGeometry" @change="onChangeSelectedGeometry">
              <a-row type="flex" justify="center">
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <label>
                    <a-radio :value="'detached'" class="card-input-element"></a-radio>
                    <a-card class="card-input" hoverable style="width: 220px; margin:12px;">
                        <img
                          slot="cover"
                          alt="example"
                          src="img/rectangle_detached/D0_RP3S30.png"
                        />
                        <a-card-meta >
                          <template slot="title" >
                            <div style="white-space: normal">
                              {{ $t('SELECTION_STEP_2_DETACHED') }}
                            </div>
                          </template>
                          <template slot="description">
                            {{ $t('SELECTION_STEP_2_DETACHED_DESC') }}
                          </template>
                        </a-card-meta>
                      </a-card>
                  </label>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <label>
                    <a-radio :value="'row'" class="card-input-element"></a-radio>
                    <a-card class="card-input" hoverable style="width: 220px; margin:12px;">
                        <img
                          slot="cover"
                          alt="example"
                          src="img/rectangle_row/R0_RP3S30.png"
                        />
                        <a-card-meta :style="{marginBottom: localLang === 'en' ? '20px' : ''}">
                          <template slot="title" >
                            <div style="white-space: normal">
                              {{ $t('SELECTION_STEP_2_ROW') }}
                            </div>
                          </template>
                          <template slot="description">
                            <div>
                              {{ $t('SELECTION_STEP_2_ROW_DESC') }}
                            </div>
                          </template>
                        </a-card-meta>
                      </a-card>
                  </label>
                </a-col>
              </a-row>
            </a-radio-group>
          </div>
          <div class="steps-content" v-if="current == 2">
            <a-divider >{{ $t('SELECTION_STEP_3_TITLE') }}</a-divider>
            <a-radio-group name="selectedPlanType" v-model="selectedPlanType" @change="onChangeSelectedPlanType">
              <a-row type="flex" justify="center">
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="selectedGeometry === 'detached'">
                  <label>
                    <a-radio :value="'square'" class="card-input-element"></a-radio>
                    <a-card class="card-input" hoverable style="width: 220px; margin:12px;">
                        <img
                          slot="cover"
                          alt="example"
                          src="img/typical_floor_plan/square_plan.jpg"
                        />
                        <a-card-meta :title="$t('SELECTION_STEP_3_SQUARE')">
                          <template slot="description">
                            {{ $t('SELECTION_STEP_3_SQUARE_DESC') }}
                          </template>
                        </a-card-meta>
                      </a-card>
                  </label>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <label>
                    <a-radio :value="'rectangular'" class="card-input-element"></a-radio>
                    <a-card class="card-input" hoverable style="width: 220px; margin:12px;">
                        <img
                          slot="cover"
                          alt="example"
                          src="img/typical_floor_plan/rectangular_plan.jpg"
                          style="margin-top:48px"
                        />
                        <a-card-meta :title="$t('SELECTION_STEP_3_RECTANGULAR')" style="margin-top: 48px;">
                          <template slot="description">
                            {{ $t('SELECTION_STEP_3_RECTANGULAR_DESC') }}
                          </template>
                        </a-card-meta>
                      </a-card>
                  </label>
                </a-col>
              </a-row>
            </a-radio-group>
          </div>
          <div class="steps-content" v-if="current == 3">
            <a-row type="flex" justify="center">
              <a-col :xs="23" :sm="24" :md="12" :lg="11" :xl="8" class="mt-2">
                <a-form-model layout="vertical" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="ruleForm" labelAlign="left" >
                  <a-tooltip placement="left">
                    <template slot="title">
                      {{ $t('SELECTION_STEP_4_BUILDING_CONSTRUCTION_PERIOD_DESC') }}
                    </template>
                    <a-form-model-item :label="$t('SELECTION_STEP_4_BUILDING_CONSTRUCTION_PERIOD')" prop="buildingConstructionPeriod">
                      <a-select default-value="2013" v-model="form.buildingConstructionPeriod" :placeholder="$t('SELECTION_STEP_4_BUILDING_CONSTRUCTION_PERIOD_REQ_MESSAGE')" :disabled="selectedObjective === 'newDesign'">
                        <a-select-option value="2000-2008">
                          2000-2008
                        </a-select-option>
                        <a-select-option value="2008-2013">
                          2008-2013
                        </a-select-option>
                        <a-select-option value="2013">
                          {{ $t('SELECTION_STEP_4_BUILDING_CONSTRUCTION_PERIOD_POST_2013_DESC') }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-tooltip>
                  <a-form-model-item :label="$t('SELECTION_STEP_4_NUMBER_OF_FLOORS')" prop="numberOfStorey">
                    <a-select v-model="form.numberOfStorey" :placeholder="$t('SELECTION_STEP_4_NUMBER_OF_FLOORS_REQ_MESSAGE')">
                      <a-select-option value="3_storey">
                        3
                      </a-select-option>
                      <a-select-option value="5_storey">
                        5
                      </a-select-option>
                      <a-select-option value="10_storey">
                        10
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item :label="$t('SELECTION_STEP_4_ROOF_TYPE')" prop="roofType">
                    <a-select v-model="form.roofType" :placeholder="$t('SELECTION_STEP_4_ROOF_TYPE_REQ_MESSAGE')">
                      <a-select-option value="pitchedRoof">
                        {{$t('SELECTION_STEP_4_PITCED')}}
                      </a-select-option>
                      <a-select-option value="terraceRoof">
                        {{$t('SELECTION_STEP_4_TERRACE')}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-tooltip placement="left">
                    <template slot="title">
                      {{ $t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO_DESC') }}
                    </template>
                    <a-form-model-item :label="$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO')" prop="windowToWallRatio">
                      <a-select v-model="form.windowToWallRatio" :placeholder="$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO_REQ_MESSAGE')">
                        <a-select-option value="30">
                          {{$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO_PERCENT_30')}}
                        </a-select-option>
                        <a-select-option value="40">
                          {{$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO_PERCENT_40')}}
                        </a-select-option>
                        <a-select-option value="50">
                          {{$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO_PERCENT_50')}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-tooltip>
                  <a-tooltip placement="left">
                    <template slot="title">
                      {{ $t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS_DESC') }}
                    </template>
                    <a-form-model-item :label="$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS')" prop="LCCAnalysisScenarios">
                      <a-select v-model="form.LCCAnalysisScenarios" :placeholder="$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS_REQ_MESSAGE')">
                        <a-select-option value="0">
                          {{$t('SELECTION_STEP_4_BASE_CASE_SCENARIO')}}
                        </a-select-option>
                        <a-select-option value="1">
                          {{$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_1')}}
                        </a-select-option>
                        <a-select-option value="2">
                            {{$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_2')}}
                        </a-select-option>
                        <a-select-option value="3">
                            {{$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_3')}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-tooltip>
                </a-form-model>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="11" :xl="12">
                <img
                  style="margin-left: 75px; width: 84%; margin-top: 6px"
                  :src="getImageSrc()"
                />
              </a-col>
            </a-row>
          </div>
          <div class="steps-action">
              <Button v-if="current > 0"  @click="prev">
                  {{ $t('SELECTION_PREV_BTN_TXT') }}
              </Button>
              <Button v-if="current < steps.length - 1" style="margin-left: 8px" type="primary" @click="next" :disabled="isDisabledNextBtn" :name="current">
                  {{ $t('SELECTION_NEXT_BTN_TXT') }}
              </Button>
              <a-space :size="8" style="float: right;margin-top: 4px;">
                <a-tooltip placement="bottom">
                  <template slot="title">
                  {{ $t('SELECTION_PARETO_BTN_DESC') }}
                  </template>
                  <Button v-if="current == steps.length - 1" type="primary" @click="onSubmit('pareto')">
                    {{ $t('SELECTION_PARETO_BTN_TXT') }}
                  </Button>
                </a-tooltip>
                <a-tooltip placement="bottom">
                  <template slot="title">
                    {{ $t('SELECTION_DESIGN_SPACE_BTN_DESC') }}
                  </template>
                  <Button v-if="current == steps.length - 1" type="primary" @click="onSubmit('parallel')">
                    {{ $t('SELECTION_DESIGN_SPACE_BTN_TXT') }}
                  </Button>
                </a-tooltip>
              </a-space>
          </div>
          <div id="modal">
            <a-modal v-model="modalShow" :ok-button-props="{ props: { disabled: true } }" :dialog-style="{ top: '1px' }">
              <template slot="title">
                {{selectedPlanType === 'square' ? $t('SELECTION_STEP_3_MODAL_SQUARE_TITLE') : $t('SELECTION_STEP_3_MODAL_RECTANGULAR_TITLE')}}
              </template>
              <template slot="footer">
                <p style="text-align:left !important; font-size:14px;">i: {{$t('SELECTION_STEP_3_MODAL_I_DESC')}}</p>
              </template>
              <a-descriptions bordered  size="small">
                <a-descriptions-item :label="$t('SELECTION_STEP_3_MODAL_EXTERIOR_DIMENSIONS')" span="12">
                  {{selectedPlanType === 'square' ? '20m x 20m' : '20m x 10m'}}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('SELECTION_STEP_3_MODAL_AREA_PER_FLOOR')" span="12">
                  {{selectedPlanType === 'square' ? '400 m²' : '200 m²'}}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('SELECTION_STEP_3_MODAL_HOUSING_UNIT_PER_FLOOR')" span="12">
                  {{selectedPlanType === 'square' ? '4' : '2'}}
                </a-descriptions-item>
                <a-descriptions-item span="12">
                  <template slot="label">
                   {{$t('SELECTION_STEP_3_MODAL_ASPECT_RATIO')}} (H/W) <sup>i</sup>
                  </template>
                  1.00
                </a-descriptions-item>
                <a-descriptions-item :label="$t('SELECTION_STEP_3_MODAL_ORIENTATION')" span="12">
                  {{selectedPlanType === 'square' ? $t('SELECTION_STEP_3_MODAL_ORIENTATION_90_VALUE') : $t('SELECTION_STEP_3_MODAL_ORIENTATION_0_VALUE')}}
                </a-descriptions-item>
              </a-descriptions>
            </a-modal>
          </div>
      </div>
  </div>
</template>
<script>
import {Button, Steps, message, Card, Row, Col, Radio, Modal, Descriptions, FormModel, Select,Tooltip, Space, Divider } from 'ant-design-vue';
export default {
    components: {
        'a-row': Row,
        'a-col': Col,
        Button,
        Steps,
        'Step': Steps.Step,
        'a-card': Card,
        'a-card-meta':Card.Meta,
        'a-radio': Radio,
        'a-radio-group': Radio.Group,
        'a-modal': Modal,
        'a-descriptions': Descriptions,
        'a-descriptions-item': Descriptions.Item,
        'a-form-model': FormModel,
        'a-form-model-item': FormModel.Item,
        'a-select': Select,
        'a-select-option': Select.Option,
        'a-tooltip': Tooltip,
        'a-space': Space,
        'a-divider': Divider
    },
  data() {
    return {
      message: message,
      current: 0,
      selectedObjective: '',
      selectedGeometry: '',
      selectedPlanType: '',
      isDisabledNextBtn: true,
      modalShow: false,
      steps: [
        {
          title: null
        },
        {
          title: null
        },
        {
          title: null
        },
        {
          title: null
        }
      ],
      labelCol: {},
      wrapperCol: {},
      form: {
        buildingConstructionPeriod: undefined,
        numberOfStorey: undefined,
        roofType: undefined,
        windowToWallRatio: undefined,
        LCCAnalysisScenarios: undefined
      },
      rules: {
        buildingConstructionPeriod: [{ required: true, message:  this.$t('SELECTION_STEP_4_BUILDING_CONSTRUCTION_PERIOD_REQ_MESSAGE'), trigger: 'change' }],
        numberOfStorey: [{ required: true, message: this.$t('SELECTION_STEP_4_NUMBER_OF_FLOORS_REQ_MESSAGE'), trigger: 'change' }],
        roofType: [{ required: true, message: this.$t('SELECTION_STEP_4_ROOF_TYPE_REQ_MESSAGE'), trigger: 'change' }],
        windowToWallRatio: [{ required: true, message: this.$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO_REQ_MESSAGE'), trigger: 'change' }],
        LCCAnalysisScenarios: [{ required: true, message: this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS_REQ_MESSAGE'), trigger: 'change' }],
      },
      localLang: ''
    };
  },
  methods: {
    onSubmit(val) {
      this.$refs.ruleForm.validate(valid => {
        var selectedData = {
            selectedObjective: this.selectedObjective,
            selectedGeometry: this.selectedGeometry,
            selectedPlanType: this.selectedPlanType,
            buildingConstructionPeriod: this.form.buildingConstructionPeriod,
            numberOfStorey: this.form.numberOfStorey,
            roofType: this.form.roofType,
            windowToWallRatio: this.form.windowToWallRatio,
            LCCAnalysisScenarios: this.form.LCCAnalysisScenarios
          }
          localStorage.setItem("form", JSON.stringify(selectedData))
        if (valid && val === 'pareto') {
          this.$router.push('pareto')
        }
        if (valid && val === 'parallel') {
          this.$router.push('parallel')
        }
      });
    },
    next() {
      this.current++;
      if (this.current === 0 && !this.selectedObjective) {
        this.isDisabledNextBtn = true;
      }
      if (this.current === 1 && !this.selectedGeometry) {
        this.isDisabledNextBtn = true;
      }
      if (this.current === 2 && !this.selectedPlanType) {
        this.isDisabledNextBtn = true;
      }

      if (this.current === 3 && this.selectedObjective === 'newDesign') {
        this.form.buildingConstructionPeriod = '2013'
      }

    },
    prev() {
      this.current--;
      this.isDisabledNextBtn = false;
    },
    onChangeSelectedObjective(e) {
      this.steps[0].title = this.selectedObjective === 'newDesign' ? this.$t('SELECTION_STEP_1_NEW_DESIGN') : this.$t('SELECTION_STEP_1_RETROFIT')
      this.isDisabledNextBtn = false;
    },
    onChangeSelectedGeometry(e) {
      this.steps[1].title = this.selectedGeometry === 'detached' ? this.$t('SELECTION_STEP_2_DETACHED') : this.$t('SELECTION_STEP_2_ROW')
      this.isDisabledNextBtn = false;
    },
    onChangeSelectedPlanType(e) {
      this.steps[2].title = this.selectedPlanType === 'square' ? this.$t('SELECTION_STEP_3_SQUARE') : this.$t('SELECTION_STEP_3_RECTANGULAR')
      this.isDisabledNextBtn = false;
      this.modalShow = true;
    },
    getImageSrc(){
      if (this.selectedGeometry === 'detached' && this.selectedPlanType === 'square') {
        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP3S30.png' : 'img/square_detached/D90_ST3S30.png'
        }

        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP3S40.png' : 'img/square_detached/D90_ST3S40.png'
        }

        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP3S50.png' : 'img/square_detached/D90_ST3S50.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP5S30.png' : 'img/square_detached/D90_ST5S30.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP5S40.png' : 'img/square_detached/D90_ST5S40.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP5S50.png' : 'img/square_detached/D90_ST5S50.png'
        }

        if ( this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP10S30.png' : 'img/square_detached/D90_ST10S30.png'
        }

        if (this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP10S40.png' : 'img/square_detached/D90_ST10S40.png'
          
        }
        if (this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/square_detached/D90_SP10S50.png' : 'img/square_detached/D90_ST10S50.png'
        }
      }

      //detached and rectangle
      if (this.selectedGeometry === 'detached' && this.selectedPlanType !== 'square') {
        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP3S30.png' : 'img/rectangle_detached/D0_RT3S30.png'
        }

        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP3S40.png' : 'img/rectangle_detached/D0_RT3S40.png'
        }

        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP3S50.png' : 'img/rectangle_detached/D0_RT3S50.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP5S30.png' : 'img/rectangle_detached/D0_RT5S30.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP5S40.png' : 'img/rectangle_detached/D0_RT5S40.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP5S50.png' : 'img/rectangle_detached/D0_RT5S50.png'
        }

        if ( this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP10S30.png' : 'img/rectangle_detached/D0_RT10S30.png'
        }

        if (this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP10S40.png' : 'img/rectangle_detached/D0_RT10S40.png'
          
        }
        if (this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_detached/D0_RP10S50.png' : 'img/rectangle_detached/D0_RT10S50.png'
        }
      }

      //row and rectangle
      if (this.selectedGeometry !== 'detached' && this.selectedPlanType !== 'square') {
        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP3S30.png' : 'img/rectangle_row/R0_RT3S30.png'
        }

        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP3S40.png' : 'img/rectangle_row/R0_RT3S40.png'
        }

        if (this.form.numberOfStorey === '3_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP3S50.png' : 'img/rectangle_row/R0_RT3S50.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP5S30.png' : 'img/rectangle_row/R0_RT5S30.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP5S40.png' : 'img/rectangle_row/R0_RT5S40.png'
        }

        if (this.form.numberOfStorey === '5_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP5S50.png' : 'img/rectangle_row/R0_RT5S50.png'
        }

        if ( this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '30') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP10S30.png' : 'img/rectangle_row/R0_RT10S30.png'
        }

        if (this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '40') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP10S40.png' : 'img/rectangle_row/R0_RT10S40.png'
          
        }
        if (this.form.numberOfStorey === '10_storey' && this.form.windowToWallRatio === '50') {
          return  this.form.roofType === 'pitchedRoof' ? 'img/rectangle_row/R0_RP10S50.png' : 'img/rectangle_row/R0_RT10S50.png'
        }
      }

    }

  },
  mounted() {
    this.localLang = localStorage.getItem("lang")
  }
};
</script>
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
}

.steps-action {
  margin-top: 24px;
}

#starter {
  min-height: calc(100vh - 76px);
}

.card-input-element {
    /* display: none !important; */
    vertical-align: middle; margin: 0px
}
</style>
