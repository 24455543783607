<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image"></parallax>
      <div class="content-center">
        <div class="container">
          <!-- <h1 class="title">
            <div class="video-container">
              <iframe src="/videos/videoplayback.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </h1> -->
        <h1 class="display-3">{{ $t('PARALLAX_TITLE') }}</h1>
          <div class="text-center">
            <a href="https://youtu.be/YJzi5LxYzfo" class="btn btn-primary btn-icon btn-round" target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">{{ $t('HOME_WHO_WE_ARE') }}</h2>
            <h5 class="description">
              {{ $t('HOME_WHO_WE_ARE_DESC') }}
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-12">
              <img src="img/istanbul.jpg" alt="">
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <h3 class="text-center" style="font-size: 24px;font-style: italic; ">
                {{ $t('HOME_PARAGRAPH_TITLE') }}
              </h3>
              <p class="text-justify">
                {{ $t('HOME_PARAGRAPH_1') }}
              </p>
              <!-- <p>
                {{ $t('HOME_PARAGRAPH_2') }}
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <!-- <h2 class="title">Here is our team</h2> -->
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <i class="fas fa-search fa-6x text-info"></i>
                <h4 class="title">{{ $t('HOME_ICON_1') }}</h4>
                <p class="description">
                  {{ $t('HOME_ICON_1_DESC') }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <i class="fas fa-chart-bar fa-6x text-info"></i>
                <h4 class="title">{{ $t('HOME_ICON_2') }}</h4>
                <p class="description">
                  {{ $t('HOME_ICON_2_DESC') }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <i class="fas fa-tasks fa-6x text-info"></i>
                <h4 class="title">{{ $t('HOME_ICON_3') }}</h4>
                <p class="description">
                  {{ $t('HOME_ICON_3_DESC') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">{{ $t('CONTACT_TITLE') }}</h2>
        <p class="description">{{ $t('CONTACT_DESC') }}</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="First Name..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                :placeholder="$t('CONTACT_MESSAGE_INPUT_TXT')"
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="info" round block size="lg" v-on:click="onSubmit(form)"
                >{{ $t('CONTACT_SEND_BTN_TXT') }}</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { notification } from 'ant-design-vue';
import { Button, FormGroupInput  } from '@/components';
import apiPareto from '../api/pareto'
export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    onSubmit(form){
      if (form.firstName.trim() === '' || form.email.trim() === '' || form.message.trim() === '') {
              notification.open({
                message: this.$t('LANDING_FORM_REQUIRED_MESSAGE'),
                placement: 'bottomRight',
              });
        return;
      }
      apiPareto.SendContact(form).then(r => {
          if(r.statusCode === 4) {
               notification.open({
                message: this.$t('LANDING_FORM_ERROR_MESG'),
                placement: 'bottomRight',
              });

              return
          }
          notification.open({
                message: this.$t('LANDING_FORM_SUCCESS_MESG'),
                placement: 'bottomRight',
              });
          this.form.firstName = '';
          this.form.email = '';
          this.form.message = '';
      })
    }
  }
};
</script>

