<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="/">{{ $t('FOOTER_HOME_BUTTON_TXT') }}</a>
          </li>
        </ul>
      </nav>
      <div class="ml-2" style="float: right;" >
        <!-- <ul v-for="lang in languages" :key="lang.info.code">
          <li @click="changeLang(lang.info.code)">{{ lang.info.name }}</li>
        </ul> -->
          <n-button type="info" style="margin: 0px 1px;" size="sm" v-for="lang in languages" :key="lang.info.code" @click="changeLang(lang.info.code)"> {{ lang.info.name }}</n-button>
      </div>
      <!-- <div class="copyright">
        &copy; {{ year }}, Designed by Yunus Alğan
      </div> -->
    </div>
  </footer>
</template>
<script>
import {Button} from '@/components';
import i18n from '@/plugins/i18n'
export default {
  components: {
    [Button.name]: Button
  },
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      languages: Object.values(i18n.messages)
    };
  },
  methods:{
    changeLang: (lang) => {
      localStorage.setItem( "lang", lang )
      location.reload();
    }
  }
};
</script>
<style></style>
