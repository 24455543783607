<template>
    <a-layout id="starter">
        <a-layout-content :style="{ background: '#fff', padding: '0 50px' }">
          <a-skeleton :loading="loading" active />
          <div :style="{ padding: '24px', minHeight: '380px' }" v-if="!loading">
            <a-page-header
                style="border: 1px solid rgb(235, 237, 240); margin-bottom: 15px"
                :title="$t('PARETO_PAGE_HEADER_TITLE')"
                @back="() => $router.go(-1)">
                <template slot="extra" >
                    <a-breadcrumb style="margin-right: 40px;">
                        <a-breadcrumb-item>{{(this.selectedFormData.selectedObjective === 'newDesign' ? this.$t('SELECTION_STEP_1_NEW_DESIGN'): this.$t('SELECTION_STEP_1_RETROFIT'))}}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{(this.selectedFormData.selectedGeometry === 'detached' ? this.$t('SELECTION_STEP_2_DETACHED') : this.$t('SELECTION_STEP_2_ROW'))}}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{(this.selectedFormData.selectedPlanType === 'square' ? this.$t('SELECTION_STEP_3_SQUARE') : this.$t('SELECTION_STEP_3_RECTANGULAR'))}}</a-breadcrumb-item>
                        <a-breadcrumb-item>
                        {{ $t('PARETO_EXTRA_OTHER_TEXT') }}
                        <a-menu slot="overlay" mode="vertical" :selectable="false">
                            <a-menu-item>
                                {{(this.$t('SELECTION_STEP_4_BUILDING_CONSTRUCTION_PERIOD') + ': ' + this.selectedFormData.buildingConstructionPeriod)}}
                            </a-menu-item>
                            <a-menu-item>
                                {{(this.$t('SELECTION_STEP_4_NUMBER_OF_FLOORS') + ': ' + this.selectedFormData.numberOfStorey.replace('_storey',''))}}
                            </a-menu-item>
                            <a-menu-item>
                                {{(this.$t('SELECTION_STEP_4_ROOF_TYPE') + ': ' + (this.selectedFormData.roofType === 'pitchedRoof' ? this.$t('SELECTION_STEP_4_PITCED') : this.$t('SELECTION_STEP_4_TERRACE')))}}
                            </a-menu-item>
                            <a-menu-item>
                                {{(this.$t('SELECTION_STEP_4_WINDOW_TO_WALL_RATIO') + ': %' + this.selectedFormData.windowToWallRatio)}}
                            </a-menu-item>
                            <a-menu-item>
                                <span v-if="selectedFormData.LCCAnalysisScenarios == 0">
                                    {{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' + this.$t('SELECTION_STEP_4_BASE_CASE_SCENARIO') }}
                                </span>
                                <span v-if="selectedFormData.LCCAnalysisScenarios == 1">{{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' +this.$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_1') }}</span>
                                <span v-if="selectedFormData.LCCAnalysisScenarios == 2">{{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' +this.$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_2') }}</span>
                                <span v-if="selectedFormData.LCCAnalysisScenarios == 3">{{this.$t('SELECTION_STEP_4_LCC_ANALYSIS_SCENARIOS') + ': ' +this.$t('SELECTION_STEP_4_SENSIVITY_ANALYSIS_3') }}</span>
                            </a-menu-item>
                        </a-menu>
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                </template>
            </a-page-header>
            <a-row>
                <a-col :span="2">
                    <div style="display: inline-block;height: 400px;">
                        <a-slider vertical range :min="YAxisMin" :max ="YAxisMax" :default-value="[YAxisMin, YAxisMax]" @afterChange="yAxisAfterChange" />
                    </div>
                </a-col>
                <a-col :span="22">
                    <highcharts :options="chartOptions" ref="chart"></highcharts>
                    <a-slider range :min="XAxisMin" :max ="XAxisMax" :default-value="[XAxisMin, XAxisMax]" @afterChange="xAxisAfterChange"/>
                </a-col>
            </a-row>
            <hr>
            <a-row v-if="!loading">
                <a-col :span="24">
                    <a-button class="editable-add-btn mb-2" v-on:click="openComparisonModal">
                        {{$t('PARETO_COMPARE_BUTTON_TEXT')}}
                    </a-button>
                    <a-button class="editable-add-btn mb-2 ml-2" v-on:click="openFilterModal">
                        {{$t('PARETO_FILTER_BUTTON_TEXT')}}
                    </a-button>
                    <a-button class="editable-add-btn mb-2 ml-2" v-on:click="openInfoModal">
                        {{$t('PARETO_INFO_BUTTON_TEXT')}}
                    </a-button>
                    <a-table :row-selection="rowSelection" :columns="selectedFormData.selectedObjective === 'newDesign' ? columns : retrofitColumns" :data-source="tableData" :rowClassName="rowClassName" :customRow="customRow" bordered>
                        <span slot="exteriorWall" v-html="$t('PARETO_EXTERIOR_WALL_CORE_MATERIAL')" :title="$t('PARETO_EXTERIOR_WALL_CORE_MATERIAL')"></span>
                        <span slot="exteriorWallThermalInsulationType" v-html="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_TYPE')" :title="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_TYPE')"></span>
                        <span slot="exteriorWallThermalInsulationThickness" v-html="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_THICKNESS')"></span>
                        <span slot="roofThermalInsulationType" v-html="$t('PARETO_ROOF_THERMAL_INSULATION_TYPE')" :title="$t('PARETO_ROOF_THERMAL_INSULATION_TYPE')"></span>
                        <span slot="roofThermalInsulationThickness" v-html="$t('PARETO_ROOF_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_ROOF_THERMAL_INSULATION_THICKNESS')"></span>
                        <span slot="groundFloorThermalInsulationThickness" v-html="$t('PARETO_GROUND_FLOOR_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_GROUND_FLOOR_THERMAL_INSULATION_THICKNESS')"></span>
                        <span slot="glazingType" v-html="$t('PARETO_GLAZING_TYPE')" :title="$t('PARETO_GLAZING_TYPE')"></span>
                        <span slot="solarContolElements" v-html="$t('PARETO_SOLAR_CONTROL_SYSTEM_TYPE')" :title="$t('PARETO_SOLAR_CONTROL_SYSTEM_TYPE')"></span>
                        <span slot="heatingSystem" v-html="$t('PARETO_HEATING_SYSTEM')" :title="$t('PARETO_HEATING_SYSTEM')"></span>
                        <span slot="coolingSystem" v-html="$t('PARETO_COOLING_SYSTEM')" :title="$t('PARETO_COOLING_SYSTEM')"></span>
                        <span slot="heatingCoolingSystem" v-html="$t('PARETO_HEATING_COOLING_SYSTEM')" :title="$t('PARETO_HEATING_COOLING_SYSTEM')"></span>
                        <span slot="domesticHotWaterSystem" v-html="$t('PARETO_HOT_WATER_SYSTEM')" :title="$t('PARETO_HOT_WATER_SYSTEM')"></span>
                        <span slot="photovoltaicSystem" v-html="$t('PARETO_PHOTOVOLTAIC_SYSTEM')" :title="$t('PARETO_PHOTOVOLTAIC_SYSTEM')"></span>
                        <span slot="primaryEnergyConsumptionSaving" v-html="$t('PARETO_PRIMARY_ENERGY_SAVING')" :title="$t('PARETO_PRIMARY_ENERGY_SAVING')"></span>
                        <span slot="lifeCycleCostTLSaving" v-html="$t('PARETO_LIFE_CYCLE_COST_SAVING')" :title="$t('PARETO_LIFE_CYCLE_COST_SAVING')"></span>
                        <span slot="primaryEnergyConsumption" v-html="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')" :title="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')"></span>
                        <span slot="lifeCycleCostTL" v-html="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')" :title="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')"></span>
                    </a-table>
                </a-col>
            </a-row>
            <div id="modal" style="width:900px">
                <a-modal v-model="modalShow" :closable="false" :dialog-style="{ top: '2px' }" :cancelText="$t('CancelButtonTxt')" :ok-button-props="{ props: { disabled: true }, style: { display: 'none' } }" size="large" width="100">
                    <a-tabs default-active-key="1">
                        <a-tab-pane key="1" :tab="$t('PARETO_COMPARE_MODAL_ENERGY_PERFORMANCE')">
                            <a-table :columns="selectedFormData.selectedObjective === 'newDesign' ? energyPerformanceColumns : energyPerformanceColumnsForRetrofit" :data-source="selectedRows" :rowClassName="rowClassName" bordered size="small">
                                <span slot="heating" v-html="$t('PARETO_HEATING')" :title="$t('PARETO_HEATING')"></span>
                                <span slot="cooling" v-html="$t('PARETO_COOLING')" :title="$t('PARETO_COOLING')"></span>
                                <span slot="lighting" v-html="$t('PARETO_LIGHTING')" :title="$t('PARETO_LIGHTING')"></span>
                                <span slot="domesticHotWater" v-html="$t('PARETO_HOT_WATER')" :title="$t('PARETO_HOT_WATER')"></span>
                                <span slot="finalEnergyConsumption" v-html="$t('PARETO_FINAL_ENERGY_CONSUMPTION')" :title="$t('PARETO_FINAL_ENERGY_CONSUMPTION')"></span>
                                <span slot="pVshare" v-html="$t('PARETO_PV_SHARE')" :title="$t('PARETO_PV_SHARE')"></span>
                                <span slot="primaryEnergyConsumption" v-html="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')" :title="$t('PARETO_PRIMARY_ENERGY_CONSUMPTION_HTML')"></span>
                                <span slot="cO2eqEmission" v-html="$t('PARETO_CO2_EMISSION_HTML')" :title="$t('PARETO_CO2_EMISSION_HTML')"></span>
                                <span slot="primaryEnergyConsumptionSaving" v-html="$t('PARETO_PE_SAVING')" :title="$t('PARETO_PE_SAVING')"></span>
                                <span slot="cO2eqEmissionSaving" v-html="$t('PARETO_CO2_SAVING')" :title="$t('PARETO_CO2_SAVING')"></span>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane key="2" :tab="$t('PARETO_COMPARE_MODAL_ECONOMIC_PERFORMANCE')" force-render>
                            <a-table :columns="selectedFormData.selectedObjective === 'newDesign' ? economicPerformanceColumns : economicPerformanceColumnsForRetrofit" :data-source="selectedRows" :rowClassName="rowClassName" bordered size="small">
                                <template slot="footer">
                                    {{$t('EXCHANGE_RATE')}}
                                </template>
                                <span slot="initialCostTL" v-html="$t('PARETO_INITIAL_COST_HTML')" :title="$t('PARETO_INITIAL_COST_HTML')"></span>
                                <span slot="annualMaintenanceCostTL" v-html="$t('PARETO_ANNUAL_MAINTENANCE_COST')" :title="$t('PARETO_ANNUAL_MAINTENANCE_COST')"></span>
                                <span slot="annualCostofNaturalGasConsumptionTL" v-html="$t('PARETO_ANNUAL_COST_OF_NATURAL_GAS_CONSUMPTION')" :title="$t('PARETO_ANNUAL_COST_OF_NATURAL_GAS_CONSUMPTION')"></span>
                                <span slot="annualCostofElectriticyConsumptionTL" v-html="$t('PARETO_ANNUAL_COST_OF_ELECTRICITY_GAS_CONSUMPTION')" :title="$t('PARETO_ANNUAL_COST_OF_ELECTRICITY_GAS_CONSUMPTION')"></span>
                                <span slot="totalOperationCostTL" v-html="$t('PARETO_TOTAL_OPERATIONAL_COST')" :title="$t('PARETO_TOTAL_OPERATIONAL_COST')"></span>
                                <span slot="lifeCycleCostTL" v-html="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')" :title="$t('PARETO_LIFE_CYCLE_COST_TL_HTML')"></span>
                                <span slot="lifeCycleCostTLSaving" v-html="$t('PARETO_LIFE_CYCLE_COST_SAVING')" :title="$t('PARETO_LIFE_CYCLE_COST_SAVING')"></span>
                                <span slot="discountedPaybackPeriod" v-html="$t('PARETO_DISCOUNTED_PAYBACK_PERIOD')" :title="$t('PARETO_DISCOUNTED_PAYBACK_PERIOD')"></span>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane key="3" :tab="$t('PARETO_COMPARE_MODAL_DECISION_VARIABLES')">
                                <a-table :columns="configurationsColumns" :data-source="selectedRows" bordered size="small" :rowClassName="rowClassName">
                                    <span slot="renewableEnergySystem" v-html="$t('PARETO_CONFIGURATION_COLUMNS_RENEWABLE_ENERGY_SYSTEM')" :title="$t('PARETO_CONFIGURATION_COLUMNS_RENEWABLE_ENERGY_SYSTEM')"></span>
                                    <span slot="configurationColumnsEnergySystem" v-html="$t('PARETO_CONFIGURATION_COLUMNS_ENERGY_SYSTEM')" :title="$t('PARETO_CONFIGURATION_COLUMNS_ENERGY_SYSTEM')"></span>
                                    <span slot="configurationColumntBuildingEnvolope" v-html="$t('PARETO_CONFIGURATION_COLUMNS_BUILDING_ENVOLOPE')" :title="$t('PARETO_CONFIGURATION_COLUMNS_BUILDING_ENVOLOPE')"></span>
                                    
                                    <span slot="exteriorWall" v-html="$t('PARETO_EXTERIOR_WALL_CORE_MATERIAL')" :title="$t('PARETO_EXTERIOR_WALL_CORE_MATERIAL')"></span>
                                    <span slot="exteriorWallThermalInsulationType" v-html="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_TYPE')" :title="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_TYPE')"></span>
                                    <span slot="exteriorWallThermalInsulationThickness" v-html="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_THICKNESS')"></span>
                                    <span slot="roofThermalInsulationType" v-html="$t('PARETO_ROOF_THERMAL_INSULATION_TYPE')" :title="$t('PARETO_ROOF_THERMAL_INSULATION_TYPE')"></span>
                                    <span slot="roofThermalInsulationThickness" v-html="$t('PARETO_ROOF_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_ROOF_THERMAL_INSULATION_THICKNESS')"></span>
                                    <span slot="groundFloorThermalInsulationThickness" v-html="$t('PARETO_GROUND_FLOOR_THERMAL_INSULATION_THICKNESS')" :title="$t('PARETO_GROUND_FLOOR_THERMAL_INSULATION_THICKNESS')"></span>
                                    <span slot="glazingType" v-html="$t('PARETO_GLAZING_TYPE')" :title="$t('PARETO_GLAZING_TYPE')"></span>
                                    <span slot="solarContolElements" v-html="$t('PARETO_SOLAR_CONTROL_SYSTEM_TYPE')" :title="$t('PARETO_SOLAR_CONTROL_SYSTEM_TYPE')"></span>

                                    <span slot="heatingSystem" v-html="$t('PARETO_HEATING_SYSTEM')" :title="$t('PARETO_HEATING_SYSTEM')"></span>
                                    <span slot="coolingSystem" v-html="$t('PARETO_COOLING_SYSTEM')" :title="$t('PARETO_COOLING_SYSTEM')"></span>
                                    <span slot="heatingCoolingSystem" v-html="$t('PARETO_HEATING_COOLING_SYSTEM')" :title="$t('PARETO_HEATING_COOLING_SYSTEM')"></span>
                                    <span slot="domesticHotWaterSystem" v-html="$t('PARETO_HOT_WATER_SYSTEM')" :title="$t('PARETO_HOT_WATER_SYSTEM')"></span>

                                    <span slot="photovoltaicSystem" v-html="$t('PARETO_PHOTOVOLTAIC_SYSTEM')" :title="$t('PARETO_PHOTOVOLTAIC_SYSTEM')"></span>

                                </a-table>                          
                        </a-tab-pane>
                    </a-tabs>
                </a-modal>
            </div>
            <div id="filterModal">
                <a-modal v-model="filterModalShow" :closable="true" :dialog-style="{ top: '2px' }" :width= "900">
                    <template slot="footer">
                        <a-button key="submit" @click="clearFilter">
                            {{$t('PARALLEL_FILTER_MODAL_RESET_TXT')}}
                        </a-button>
                        <a-button key="back" type="primary" @click="filterAllDesignSpace">
                            {{$t('PARALLEL_FILTER_MODAL_OK_TXT')}}
                        </a-button>
                    </template>
                    <a-form-model>
                        <a-row>
                            <a-col :span="7"  class="mr-1">
                                <a-form-model-item :label="$t('PARETO_EXTERIOR_WALL_CORE_MATERIAL')" prop="exteriorWall">
                                    <a-select v-model="filterForm.exteriorWall" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="Aerated Concrete Block">
                                            {{$t('PARALLEL_CHART_CATEGORIES_AAC')}}
                                        </a-select-option>
                                        <a-select-option value="Horizontal Coring Brick">
                                            {{$t('PARALLEL_CHART_CATEGORIES_HCB')}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7" class="mr-1">
                                <a-form-model-item :label="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_TYPE')" prop="exteriorWallThermalInsulationType">
                                    <a-select v-model="filterForm.exteriorWallThermalInsulationType" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="Extruded Polystyrene (XPS)">
                                            XPS
                                        </a-select-option>
                                        <a-select-option value="Stone Wool (SW)">
                                            SW
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7">
                                <a-form-model-item :label="$t('PARETO_EXTERIOR_WALL_THERMAL_INSULATION_THICKNESS')" prop="exteriorWallThermalInsulationThickness">
                                    <a-select v-model="filterForm.exteriorWallThermalInsulationThickness" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="0.22">
                                            0.22
                                        </a-select-option>
                                        <a-select-option value="0.16">
                                            0.16
                                        </a-select-option>
                                        <a-select-option value="0.10">
                                            0.10
                                        </a-select-option>
                                        <a-select-option value="0.04">
                                            0.04
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="7" class="mr-1">
                                <a-form-model-item :label="$t('PARETO_ROOF_THERMAL_INSULATION_TYPE')" prop="roofThermalInsulationType">
                                    <a-select v-model="filterForm.roofThermalInsulationType" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="Extruded Polystyrene (XPS)">
                                            XPS
                                        </a-select-option>
                                        <a-select-option value="Stone Wool (SW)">
                                            SW
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7"  class="mr-1">
                                <a-form-model-item :label="$t('PARETO_ROOF_THERMAL_INSULATION_THICKNESS')" prop="roofThermalInsulationThickness">
                                    <a-select v-model="filterForm.roofThermalInsulationThickness" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="0.28">
                                            0.28
                                        </a-select-option>
                                        <a-select-option value="0.22">
                                            0.22
                                        </a-select-option>
                                        <a-select-option value="0.16">
                                            0.16
                                        </a-select-option>
                                        <a-select-option value="0.10">
                                            0.10
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7">
                                <a-form-model-item :label="$t('PARETO_GROUND_FLOOR_THERMAL_INSULATION_THICKNESS')" prop="groundFloorThermalInsulationThickness">
                                    <a-select v-model="filterForm.groundFloorThermalInsulationThickness" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="0.22">
                                            0.22
                                        </a-select-option>
                                        <a-select-option value="0.16">
                                            0.16
                                        </a-select-option>
                                        <a-select-option value="0.10">
                                            0.10
                                        </a-select-option>
                                        <a-select-option value="0.04">
                                            0.04
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="7" class="mr-1">
                                <a-form-model-item :label="$t('PARETO_GLAZING_TYPE')" prop="glazingType">
                                    <a-select v-model="filterForm.glazingType" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <!-- <a-select-option value="Double Glazing (4+12air+4mm)">
                                            {{$t('PARALLEL_CHART_CATEGORIES_G0')}}
                                        </a-select-option> -->
                                        <a-select-option value="Double Glazing (4+12air+4mm), Low-E (heat control)">
                                            {{$t('PARALLEL_CHART_CATEGORIES_G1')}}
                                        </a-select-option>
                                        <a-select-option value="Double Glazing (4+12argon+4mm), Low-E (heat control)">
                                            {{$t('PARALLEL_CHART_CATEGORIES_G2')}}
                                        </a-select-option>
                                        <a-select-option value="Double Glazing (4+12argon+4mm), Low-E (heat-solar control)">
                                            {{$t('PARALLEL_CHART_CATEGORIES_G3')}}
                                        </a-select-option>
                                        <a-select-option value="Triple Glazing (4+16argon+4+16argon+4mm), Low-E (heat-solar control)">
                                            {{$t('PARALLEL_CHART_CATEGORIES_G4')}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7"  class="mr-1">
                                <a-form-model-item :label="$t('PARETO_SOLAR_CONTROL_SYSTEM_TYPE')" prop="solarContolElements">
                                    <a-select v-model="filterForm.solarContolElements" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="Fixed Solar Control Element (overhangs (South), overhangs and fins (East, West))">
                                            {{$t('PARALLEL_CHART_CATEGORIES_FSCE')}}
                                        </a-select-option>
                                        <a-select-option value="External Venetian Blinds (South, East, West)">
                                            {{$t('PARALLEL_CHART_CATEGORIES_EVB')}}
                                        </a-select-option>
                                        <a-select-option value="Not Available">
                                            {{$t('PARALLEL_CHART_CATEGORIES_NA')}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7">
                                <a-form-model-item :label="$t('PARETO_HEATING_SYSTEM')" prop="heatingSystem">
                                    <a-select v-model="filterForm.heatingSystem" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="0.95">
                                            0.95
                                        </a-select-option>
                                        <a-select-option value="0.86">
                                            0.86
                                        </a-select-option>
                                        <!-- <a-select-option value="Radiator System,η:0.80">
                                            0.80
                                        </a-select-option> -->
                                        <a-select-option value="Not Available">
                                            {{$t('PARALLEL_CHART_CATEGORIES_NA')}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="7" class="mr-1">
                                <a-form-model-item :label="$t('PARETO_COOLING_SYSTEM')" prop="coolingSystem">
                                    <a-select v-model="filterForm.coolingSystem" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="Split Air Conditioner, SEER:8.50">
                                            8.50
                                        </a-select-option>
                                        <a-select-option value="Split Air Conditioner, SEER:5.80">
                                            5.80
                                        </a-select-option>
                                        <!-- <a-select-option value="Split Air Conditioner, SEER:4.20">
                                            4.20
                                        </a-select-option> -->
                                        <a-select-option value="Not Available">
                                            {{$t('PARALLEL_CHART_CATEGORIES_NA')}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7"  class="mr-1">
                                <a-form-model-item :label="$t('PARETO_HEATING_COOLING_SYSTEM')" prop="heatingCoolingSystem">
                                    <a-select v-model="filterForm.heatingCoolingSystem" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="Variable Refrigerant Flow System">
                                            {{$t('PARALLEL_CHART_CATEGORIES_VRF')}}
                                        </a-select-option>
                                        <a-select-option value="Air Source Heat Pump (air-to-water)">
                                            {{$t('PARALLEL_CHART_CATEGORIES_ASHP')}}
                                        </a-select-option>
                                        <a-select-option value="Not Available">
                                            {{$t('PARALLEL_CHART_CATEGORIES_NA')}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7">
                                <a-form-model-item :label="$t('PARETO_HOT_WATER_SYSTEM')" prop="domesticHotWaterSystem">
                                    <a-select v-model="filterForm.domesticHotWaterSystem" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <!-- <a-select-option value="Stand Alone Water Heater, η:0.80">
                                            0.80
                                        </a-select-option> -->
                                        <a-select-option value="0.86">
                                            0.86
                                        </a-select-option>
                                        <a-select-option value="2.41">
                                            2.41
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="7" class="mr-1">
                                <a-form-model-item :label="$t('PARETO_PHOTOVOLTAIC_SYSTEM')" prop="photovoltaicSystem">
                                    <a-select v-model="filterForm.photovoltaicSystem" :placeholder="$t('PARALLEL_SELECT_OPTION_TXT')">
                                        <a-select-option value="Rooftop PV System (10.5kW)">
                                            10.5kWp
                                        </a-select-option>
                                        <a-select-option value="Not Available">
                                            {{$t('PARALLEL_CHART_CATEGORIES_NA')}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7"  class="mr-1" v-if="selectedFormData.selectedObjective === 'newDesign'">
                                <a-form-model-item :label="$t('PARALLEL_PEC_TXT')" prop="primaryEnergyConsumption">
                                    <a-input-group compact>
                                        <a-input-number style=" width: 110px; text-align: center" :step="0.1" v-model="filterForm.primaryEnergyConsumptionMin" :min="primaryEnergyConsumptionMin" :max="primaryEnergyConsumptionMax" placeholder="Minimum"/>
                                        <a-input
                                            style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"
                                            placeholder="~"
                                            disabled
                                        />
                                        <a-input-number style="width: 110px; text-align: center; border-left: 0" :step="0.1" v-model="filterForm.primaryEnergyConsumptionMax" :min="primaryEnergyConsumptionMin" :max="primaryEnergyConsumptionMax" placeholder="Maximum"/>
                                    </a-input-group>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7" v-if="selectedFormData.selectedObjective === 'newDesign'">
                                <a-form-model-item :label="$t('PARETO_LIFE_CYCLE_COST_TL')" prop="lifeCycleCost">
                                    <a-input-group compact>
                                        <a-input-number style=" width: 110px; text-align: center" :step="0.1" v-model="filterForm.lifeCycleCostMin" :min="lifeCycleCostMin" :max="lifeCycleCostMax" placeholder="Minimum"/>
                                        <a-input
                                            style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"
                                            placeholder="~"
                                            disabled
                                        />
                                        <a-input-number style="width: 110px; text-align: center; border-left: 0" :step="0.1" v-model="filterForm.lifeCycleCostMax" :min="lifeCycleCostMin" :max="lifeCycleCostMax" placeholder="Maximum"/>
                                    </a-input-group>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7"  class="mr-1" v-if="selectedFormData.selectedObjective === 'retrofit'">
                                <a-form-model-item :label="$t('PARALLEL_PRIMARY_ENERGY_SAVING')" prop="primaryEnergyConsumptionSaving">
                                    <a-input-group compact>
                                        <a-input-number style=" width: 110px; text-align: center" :step="0.1" v-model="filterForm.primaryEnergyConsumptionSavingMin" :min="primaryEnergyConsumptionMin" :max="primaryEnergyConsumptionMax" placeholder="Minimum"/>
                                        <a-input
                                            style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"
                                            placeholder="~"
                                            disabled
                                        />
                                        <a-input-number style="width: 110px; text-align: center; border-left: 0" :step="0.1" v-model="filterForm.primaryEnergyConsumptionSavingMax" :min="primaryEnergyConsumptionMin" :max="primaryEnergyConsumptionMax" placeholder="Maximum"/>
                                    </a-input-group>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="7" v-if="selectedFormData.selectedObjective === 'retrofit'">
                                <a-form-model-item :label="$t('PARALLEL_LIFE_CYCLE_COST_SAVING')" prop="lifeCycleCostSavingMin">
                                    <a-input-group compact>
                                        <a-input-number style=" width: 110px; text-align: center" :step="0.1" v-model="filterForm.lifeCycleCostSavingMin" :min="lifeCycleCostMin" :max="lifeCycleCostMax" placeholder="Minimum"/>
                                        <a-input
                                            style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"
                                            placeholder="~"
                                            disabled
                                        />
                                        <a-input-number style="width: 110px; text-align: center; border-left: 0" :step="0.1" v-model="filterForm.lifeCycleCostSavingMax" :min="lifeCycleCostMin" :max="lifeCycleCostMax" placeholder="Maximum"/>
                                    </a-input-group>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-form-model>
                </a-modal>
            </div>
            <div id="infoModal">
                <a-modal v-model="infoModalShow" :closable="false" :dialog-style="{ top: '2px' }" :cancelText="$t('CancelButtonTxt')" :ok-button-props="{ props: { disabled: true }, style: { display: 'none' } }" :width="900">
                    <img :src="$t('PARETO_INFO_IMAGE_0')" alt="" srcset="" style="margin-top: -100px">
                    <img :src="$t('PARETO_INFO_IMAGE_1')" alt="" srcset="" style="margin-top: -200px">
                    <img :src="$t('PARETO_INFO_IMAGE_2')" alt="" srcset="" style="margin-top: -200px">
                    <img :src="$t('PARETO_INFO_IMAGE_3')" alt="" srcset="" style="margin-top: -200px">
                    <img :src="$t('PARETO_INFO_IMAGE_4')" alt="" srcset="" style="margin-top: -200px">
                </a-modal>
            </div>
          </div>
        </a-layout-content>
    </a-layout>
</template>
<script>
import { Layout, Row, Col, Table, Button, Skeleton, Modal, Slider, Tabs, PageHeader, Breadcrumb, Menu, FormModel, Select, InputNumber, Input} from 'ant-design-vue';
import {Chart} from 'highcharts-vue'
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import offlineExporting from "highcharts/modules/offline-exporting";
import apiPareto from '../api/pareto'
import parallelCoordinates from 'highcharts/modules/parallel-coordinates.js'
import message from "ant-design-vue/lib/message";
import VueViewer from 'v-viewer'


stockInit(Highcharts);
parallelCoordinates(Highcharts);
exportingInit(Highcharts);
offlineExporting(Highcharts);

export default {
    components: {
        'a-layout': Layout,
        'a-layout-content': Layout.Content,
        'a-row': Row,
        'a-col': Col,
        'a-table': Table,
        'a-skeleton': Skeleton,
        highcharts: Chart,
        'a-page-header': PageHeader,
        'a-breadcrumb': Breadcrumb,
        'a-breadcrumb-item': Breadcrumb.Item,
        'a-menu': Menu,
        'a-menu-item': Menu.Item,
        'a-button': Button,
        'a-modal': Modal,
        'a-tabs': Tabs,
        'a-tab-pane': Tabs.TabPane,
        'a-slider': Slider,
        'a-form-model': FormModel,
        'a-form-model-item': FormModel.Item,
        'a-select': Select,
        'a-select-option': Select.Option,
        'a-input-number': InputNumber,
        'a-input-group': Input.Group,
        'a-input': Input
    },
    data () {
        var self = this;
       return {
            selectedFormData: {},
            tableData: [],
            columns: [
                { dataIndex: 'exteriorWall', key: 'exteriorWall', width: 100, slots: {title:'exteriorWall'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'exteriorWallThermalInsulationType', key: 'exteriorWallThermalInsulationType', width: 100, slots: {title:'exteriorWallThermalInsulationType'}, customRender: (text, row, index) => {return text === 0 ? '-' : text} ,ellipsis: true},
                { dataIndex: 'exteriorWallThermalInsulationThickness', key: 'exteriorWallThermalInsulationThickness', width: 100, slots: {title:'exteriorWallThermalInsulationThickness'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'roofThermalInsulationType', key: 'roofThermalInsulationType', width: 100, slots: {title:'roofThermalInsulationType'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'roofThermalInsulationThickness', key: 'roofThermalInsulationThickness', width: 100, slots: {title:'roofThermalInsulationThickness'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'groundFloorThermalInsulationThickness', key: 'groundFloorThermalInsulationThickness', width: 100, slots: {title:'groundFloorThermalInsulationThickness'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'glazingType', key: 'glazingType', width: 100, slots: {title:'glazingType'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'solarContolElements', key: 'solarContolElements', width: 100, slots: {title:'solarContolElements'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'heatingSystem', key: 'heatingSystem', width: 100, slots: {title:'heatingSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'coolingSystem', key: 'coolingSystem', width: 100, slots: {title:'coolingSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'heatingCoolingSystem', key: 'heatingCoolingSystem', width: 100, slots: {title:'heatingCoolingSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'domesticHotWaterSystem', key: 'domesticHotWaterSystem', width: 100, slots: {title:'domesticHotWaterSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'photovoltaicSystem', key: 'photovoltaicSystem', width: 100, slots: {title:'photovoltaicSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },

                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption', width: 100, slots: {title:'primaryEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL', width: 100, slots: {title:'lifeCycleCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
            ],
            retrofitColumns: [
                { dataIndex: 'exteriorWall', key: 'exteriorWall', width: 100, slots: {title:'exteriorWall'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'exteriorWallThermalInsulationType', key: 'exteriorWallThermalInsulationType', width: 100, slots: {title:'exteriorWallThermalInsulationType'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'exteriorWallThermalInsulationThickness', key: 'exteriorWallThermalInsulationThickness', width: 100, slots: {title:'exteriorWallThermalInsulationThickness'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'roofThermalInsulationType', key: 'roofThermalInsulationType', width: 100, slots: {title:'roofThermalInsulationType'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'roofThermalInsulationThickness', key: 'roofThermalInsulationThickness', width: 100, slots: {title:'roofThermalInsulationThickness'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'groundFloorThermalInsulationThickness', key: 'groundFloorThermalInsulationThickness', width: 100, slots: {title:'groundFloorThermalInsulationThickness'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'glazingType', key: 'glazingType', width: 100, slots: {title:'glazingType'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'solarContolElements', key: 'solarContolElements', width: 100, slots: {title:'solarContolElements'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'heatingSystem', key: 'heatingSystem', width: 100, slots: {title:'heatingSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'coolingSystem', key: 'coolingSystem', width: 100, slots: {title:'coolingSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'heatingCoolingSystem', key: 'heatingCoolingSystem', width: 100, slots: {title:'heatingCoolingSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'domesticHotWaterSystem', key: 'domesticHotWaterSystem', width: 100, slots: {title:'domesticHotWaterSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'photovoltaicSystem', key: 'photovoltaicSystem', width: 100, slots: {title:'photovoltaicSystem'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption', width: 100, slots: {title:'primaryEnergyConsumptionSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL', width: 100, slots: {title:'lifeCycleCostTLSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { title: 'Id', dataIndex: 'caseNo', key: 'caseNo',width: 0, defaultSortOrder: 'descend', sorter: (a, b) => a.caseNo - b.caseNo, className: 'd-none' },
            ],
            energyPerformanceColumns: [
                { dataIndex: 'heating', key: 'heating',width: 100, slots: {title:'heating'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cooling', key: 'cooling',width: 100, slots: {title:'cooling'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lighting', key: 'lighting',width: 100, slots: {title:'lighting'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'domesticHotWater', key: 'domesticHotWater',width: 100, slots: {title:'domesticHotWater'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'finalEnergyConsumption', key: 'finalEnergyConsumption',width: 100, slots: {title:'finalEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption',width: 100, slots: {title:'primaryEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cO2eqEmission', key: 'cO2eqEmission',width: 100, slots: {title:'cO2eqEmission'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'pVshare', key: 'pVshare',width: 100, slots: {title:'pVshare'},customRender: (text, row, index) => {return text === 0 ? '-' : text} }
            ],
            energyPerformanceColumnsForRetrofit: [
                { dataIndex: 'heating', key: 'heating',width: 100, slots: {title:'heating'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cooling', key: 'cooling',width: 100, slots: {title:'cooling'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lighting', key: 'lighting',width: 100, slots: {title:'lighting'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'domesticHotWater', key: 'domesticHotWater',width: 100,slots: {title:'domesticHotWater'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'finalEnergyConsumption', key: 'finalEnergyConsumption',width: 100, slots: {title:'finalEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumptionForRetrofit', key: 'primaryEnergyConsumptionForRetrofit',width: 100,slots: {title:'primaryEnergyConsumption'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true  },
                { dataIndex: 'cO2eqEmissionForRetrofit', key: 'cO2eqEmissionForRetrofit',width: 100, slots: {title:'cO2eqEmission'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'primaryEnergyConsumption', key: 'primaryEnergyConsumption',width: 100, slots: {title:'primaryEnergyConsumptionSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'cO2eqEmission', key: 'cO2eqEmission',width: 100, slots: {title:'cO2eqEmissionSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'pVshare', key: 'pVshare',width: 100, slots: {title:'pVshare'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
            ],
            economicPerformanceColumns: [
                { dataIndex: 'initialCostTL', key: 'initialCostTL',width: 100, slots: {title:'initialCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualMaintenanceCostTL', key: 'annualMaintenanceCostTL',width: 100, slots: {title:'annualMaintenanceCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                { dataIndex: 'annualCostofNaturalGasConsumptionTL', key: 'annualCostofNaturalGasConsumptionTL',width: 100, slots: {title:'annualCostofNaturalGasConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualCostofElectriticyConsumptionTL', key: 'annualCostofElectriticyConsumptionTL',width: 100, slots: {title:'annualCostofElectriticyConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'totalOperationCostTL', key: 'totalOperationCostTL',width: 100, slots: {title:'totalOperationCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL',width: 100, slots: {title:'lifeCycleCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true }
            ],
            economicPerformanceColumnsForRetrofit: [
                { dataIndex: 'initialCostTL', key: 'initialCostTL',width: 100,slots: {title:'initialCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualMaintenanceCostTL', key: 'annualMaintenanceCostTL',width: 100,slots: {title:'annualMaintenanceCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualCostofNaturalGasConsumptionTL', key: 'annualCostofNaturalGasConsumptionTL',width: 100,slots: {title:'annualCostofNaturalGasConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'annualCostofElectriticyConsumptionTL', key: 'annualCostofElectriticyConsumptionTL',width: 100,slots: {title:'annualCostofElectriticyConsumptionTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'totalOperationCostTL', key: 'totalOperationCostTL',width: 100,slots: {title:'totalOperationCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTLForRetrofit', key: 'lifeCycleCostTLForRetrofit',width: 100, slots: {title:'lifeCycleCostTL'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'lifeCycleCostTL', key: 'lifeCycleCostTL',width: 100, slots: {title:'lifeCycleCostTLSaving'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                { dataIndex: 'discountedPaybackPeriod', key: 'discountedPaybackPeriod',width: 100, slots: {title:'discountedPaybackPeriod'},customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
            ],
            configurationsColumns:[
                {
                    slots: { title:'configurationColumntBuildingEnvolope' },
                    width: 100,
                    ellipsis: true,
                    children: [
                        { slots: { title:'exteriorWall' }, dataIndex: 'exteriorWall', key: 'exteriorWall', width: 100, customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'exteriorWallThermalInsulationType' }, dataIndex: 'exteriorWallThermalInsulationType', key: 'exteriorWallThermalInsulationType', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                        { slots: { title:'exteriorWallThermalInsulationThickness' }, dataIndex: 'exteriorWallThermalInsulationThickness', key: 'exteriorWallThermalInsulationThickness', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                        { slots: { title:'roofThermalInsulationType' }, dataIndex: 'roofThermalInsulationType', key: 'roofThermalInsulationType', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'roofThermalInsulationThickness' }, dataIndex: 'roofThermalInsulationThickness', key: 'roofThermalInsulationThickness', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'groundFloorThermalInsulationThickness' }, dataIndex: 'groundFloorThermalInsulationThickness', key: 'groundFloorThermalInsulationThickness', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'glazingType' }, dataIndex: 'glazingType', key: 'glazingType', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                        { slots: { title:'solarContolElements' }, dataIndex: 'solarContolElements', key: 'solarContolElements', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true},
                    ]
                },
                {
                    slots: {title:'configurationColumnsEnergySystem'},
                    width: 100,
                    ellipsis: true,
                    children: [
                        { slots: { title:'heatingSystem' }, dataIndex: 'heatingSystem', key: 'heatingSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'coolingSystem' }, dataIndex: 'coolingSystem', key: 'coolingSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'heatingCoolingSystem' }, dataIndex: 'heatingCoolingSystem', key: 'heatingCoolingSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                        { slots: { title:'domesticHotWaterSystem' }, dataIndex: 'domesticHotWaterSystem', key: 'domesticHotWaterSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text}, ellipsis: true },
                    ]
                },
                {
                    slots: {title:'renewableEnergySystem'},
                    width: 100,
                    ellipsis: true,
                    children: [
                        { slots: { title:'photovoltaicSystem' }, dataIndex: 'photovoltaicSystem', key: 'photovoltaicSystem', width: 100,customRender: (text, row, index) => {return text === 0 ? '-' : text},ellipsis: true }
                    ]
                }
            ],
            loading: true,
            chartOptions: {
                chart: {
                    type: 'spline',
                    parallelCoordinates: true,
                    parallelAxes: {
                        lineWidth: 2
                    },
                    events: {
                            load() {
                                if (self.selectedFormData.selectedObjective !== 'newDesign') {
                                    this.series[0].xAxis.categories[13] = self.$t('PARALLEL_CHART_CATEGORIES_PEC_SAVING')
                                    this.series[0].xAxis.categories[14] = self.$t('PARALLEL_CHART_CATEGORIES_LCC_SAVING')
                                    this.series[0].xAxis.setCategories(this.series[0].xAxis.categories);
                                }
                            }
                    }
                },
                title: {
                    text: self.$t('SELECTION_DESIGN_SPACE_BTN_TXT')
                },
                plotOptions: {
                    series: {
                        allowPointSelect: true,
                        point: {
                            events: {
                                select: function(e) {
                                    var getSelectedPoints = this.series.chart.getSelectedPoints();
                                    let selectedPoints = getSelectedPoints.map(item => {
                                            const container = {};
                                            container.index = item.index;
                                            container.value = item.formattedValue;
                                            return container;
                                        });
                                        self.pointSelection(selectedPoints)
                                },
                                unselect: function(e) {
                                    var getSelectedPoints = this.series.chart.getSelectedPoints();
                                    let selectedPoints = getSelectedPoints.map(item => {
                                            const container = {};
                                            container.index = item.index;
                                            container.value = item.formattedValue;
                                            return container;
                                        });
                                        self.pointSelection(selectedPoints)
                                }
                            }
                        },
                        animation: false,
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        },
                        states: {
                            hover: {
                                halo: {
                                    size: 0
                                }
                            }
                        },
                        events: {
                            mouseOver: function () {
                                // this.group.toFront();
                            }
                        }
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span>' +
                    '{series.name}: <b>{point.formattedValue}</b><br/>'
                },
                xAxis: {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_EXTERIOR_WALL_CORE_MATERIAL'),
                        self.$t('PARALLEL_CHART_CATEGORIES_EXTERIOR_WALL_TIT'),
                        self.$t('PARALLEL_CHART_CATEGORIES_EXTERIOR_WALL_THICKNESS'),
                        self.$t('PARALLEL_CHART_CATEGORIES_ROOF_INS_TYPE'),
                        self.$t('PARALLEL_CHART_CATEGORIES_ROOF_INS_THICKNESS'),
                        self.$t('PARALLEL_CHART_CATEGORIES_GFT_INS_THICKNESS'),
                        self.$t('PARALLEL_CHART_CATEGORIES_GLAZING_TYPE'),
                        self.$t('PARALLEL_CHART_CATEGORIES_SOLAR_CNTRL_SYS_TYPE'),
                        self.$t('PARALLEL_CHART_CATEGORIES_HEATING_SYSTEM'),
                        self.$t('PARALLEL_CHART_CATEGORIES_COOLING_SYSTEM'),
                        self.$t('PARALLEL_CHART_CATEGORIES_HETING_COOLING_SYSTEM'),
                        self.$t('PARALLEL_CHART_CATEGORIES_HOT_WATER_SYSTEM'),
                        self.$t('PARALLEL_CHART_CATEGORIES_PV_SYSTEM'),
                        self.$t('PARALLEL_CHART_CATEGORIES_PEC'),
                        self.$t('PARALLEL_CHART_CATEGORIES_LCC'),
                    ],
                    offset: 10,
                    labels: {
                        formatter () {
                            return `<span style="position: fixed;">${this.value}</span>`
                        },
                        // align: 'center',
                        x: 0,
                        y: -30
                    }
                },
                yAxis: [
                {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_HCB'),
                        self.$t('PARALLEL_CHART_CATEGORIES_AAC'),
                    ]
                },
                {
                   categories: [
                        'SW',
                        'XPS',
                    ]
                },
                {
                    categories: [
                        '0.04',
                        '0.10',
                        '0.16',
                        '0.22',

                    ]
                },
                {
                   categories: [
                        'SW',
                        'XPS',
                    ]
                },
                {
                    categories: [
                        '0.10',
                        '0.16',
                        '0.22',
                        '0.28',

                    ]
                },
                {
                    categories: [
                        '0.04',
                        '0.10',
                        '0.16',
                        '0.22',

                    ]
                },
                {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_G0'),
                        self.$t('PARALLEL_CHART_CATEGORIES_G1'),
                        self.$t('PARALLEL_CHART_CATEGORIES_G2'),
                        self.$t('PARALLEL_CHART_CATEGORIES_G3'),
                        self.$t('PARALLEL_CHART_CATEGORIES_G4')
                    ]
                },
                {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_NA'),
                        self.$t('PARALLEL_CHART_CATEGORIES_EVB'),
                        self.$t('PARALLEL_CHART_CATEGORIES_FSCE')
                    ]
                },
                {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_NA'),
                        '0.80',
                        '0.86',
                        '0.95'
                    ]
                },
                {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_NA'),
                        '4.20',
                        '5.80',
                        '8.50'
                    ]
                },
                {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_NA'),
                        self.$t('PARALLEL_CHART_CATEGORIES_ASHP'),
                        self.$t('PARALLEL_CHART_CATEGORIES_VRF')
                    ]
                },
                {
                    categories: [
                        '0.80',
                        '0.86',
                        '2.41'
                    ]
                },
                {
                    categories: [
                        self.$t('PARALLEL_CHART_CATEGORIES_NA'),
                        '10.5kWp'
                    ]
                },
                {
                    endOnTick: false,
                    startOnTick: false,
                    tooltipValueFormat: '{value}'
                },
                {
                    endOnTick: false,
                    startOnTick: false,
                    tooltipValueFormat: '{value}'
                }
                ],
                // colors: ['rgba(11, 200, 200, 0.5)'],
                series: []
            },
            modalShow: false,
            filterModalShow: false,
            infoModalShow: false,
            selectedRows: [],
            YAxisMin: null,
            YAxisMax: null,
            XAxisMin: null,
            XAxisMax: null,
            filterForm: {
                exteriorWall: undefined,
				exteriorWallThermalInsulationType:undefined,
				exteriorWallThermalInsulationThickness:undefined,
				roofThermalInsulationType:undefined,
				roofThermalInsulationThickness:undefined,
				groundFloorThermalInsulationThickness:undefined,
				glazingType:undefined,
				solarContolElements:undefined,
				heatingSystem:undefined,
				coolingSystem:undefined,
				heatingCoolingSystem:undefined,
				domesticHotWaterSystem:undefined,
				photovoltaicSystem:undefined,
				lifeCycleCostMin:undefined,
				lifeCycleCostMax:undefined,
                lifeCycleCostSavingMin:undefined,
				lifeCycleCostSavingMax:undefined,
                primaryEnergyConsumptionMin:undefined,
				primaryEnergyConsumptionMax:undefined,
                primaryEnergyConsumptionSavingMin:undefined,
				primaryEnergyConsumptionSavingMax:undefined,
            },
            localLang: '',
            primaryEnergyConsumptionMin: undefined,
            primaryEnergyConsumptionMax: undefined,
            lifeCycleCostMin: undefined,
            lifeCycleCostMax: undefined,
            images: [
                    "https://picsum.photos/200/200",
                    "https://picsum.photos/300/200",
                    "https://picsum.photos/250/200"
                    ]
       }
    },
    methods: {
        rowClassName(record,index) {
            let className = "";
            if (record.key === 147457 || record.key === 147458 || record.key === 147459) className = "bg-info";
            return className;
        },
        customRow(record, index) {
            return {
                on: {
                mouseenter: () => {
                    if (record.key === 147457 || record.key === 147458 || record.key === 147459) {
                        message.info(this.$t('PARETO_TABLE_ROW_BASE_CASE_INFO_TXT'));
                    }
                    },
                },
            };
        },
        openComparisonModal(e) {
            this.modalShow = true
        },
        openFilterModal (e){
            this.filterModalShow = true
        },
        openInfoModal (e){
            this.infoModalShow = true
        },
        filterAllDesignSpace (e){
            const asArray = Object.entries(this.filterForm);
            if (asArray.filter(([key, value]) => value === undefined && !key.includes("Min") && !key.includes("Max")).length >= 11) {
                message.error(this.$t('PARALLEL_FILTER_MODAL_SELECT_COUNT_ERROR'));
                return
            }

            if(this.filterForm.heatingSystem !== undefined && this.filterForm.heatingSystem !== 'Not Available') {
                if(this.filterForm.heatingCoolingSystem !== undefined && this.filterForm.heatingCoolingSystem !== 'Not Available') {
                    message.error(this.$t('PARALLEL_FILTER_MODAL_HEATING_COOLING_SELECT_ERROR'));
                    return
                }
            }
            if(this.filterForm.coolingSystem !== undefined && this.filterForm.coolingSystem !== 'Not Available') {
                if(this.filterForm.heatingCoolingSystem !== undefined && this.filterForm.heatingCoolingSystem !== 'Not Available') {
                    message.error(this.$t('PARALLEL_FILTER_MODAL_HEATING_COOLING_SELECT_ERROR'));
                    return
                }
            }
                        
            if (this.filterForm.heatingCoolingSystem !== undefined && this.filterForm.heatingCoolingSystem !== 'Not Available') {
                if (this.filterForm.coolingSystem !== undefined && this.filterForm.coolingSystem !== 'Not Available') {
                    message.error(this.$t('PARALLEL_FILTER_MODAL_COOLING_SELECT_ERROR'));
                    return
                }
                if (this.filterForm.heatingSystem !== undefined && this.filterForm.heatingSystem !== 'Not Available') {
                    message.error(this.$t('PARALLEL_FILTER_MODAL_HEATING_SELECT_ERROR'));
                    return
                }
            }


            this.$nextTick(function () {
                var retrievedObject = localStorage.getItem('form');
                this.selectedFormData = JSON.parse(retrievedObject)

                var merged = {...this.selectedFormData, ...this.filterForm};
                apiPareto.FilterAllDesignSpace(merged).then(r => {
                    if(r.statusCode === 4) {
                        message.error(this.$t('PARALLEL_FILTER_MODAL_DATA_NOT_FOUND_ERROR'));
                        return
                    }
                    this.loading = true;
                    this.chartOptions.series = r.data.chartData.map(function (set, i) {
                        return {
                        name: '',
                        data: set,
                        shadow: false
                        };
                    })
                    this.tableData = r.data.paretoSolutionsOutput
                    this.YAxisMin = r.data.yAxisMin
                    this.YAxisMax = r.data.yAxisMax
                    this.XAxisMin = r.data.xAxisMin
                    this.XAxisMax = r.data.xAxisMax
                    this.loading = false;
                    this.filterModalShow = false
                })
            })
        },
        clearFilter (e) {
            location.reload();
        },
        yAxisAfterChange(value){
            this.$refs.chart.chart.showLoading();
            apiPareto.GetAllDesignSpace(this.selectedFormData).then(r => {
                var baseCases = r.data.paretoSolutionsOutput.filter(x=> x.caseNo === 147457 || x.caseNo === 147458 || x.caseNo === 147459)
                this.tableData = r.data.paretoSolutionsOutput.filter(x => x.lifeCycleCostTL > value[0] && x.lifeCycleCostTL < value[1])
                this.tableData.shift()
                if(this.selectedFormData.selectedObjective === 'retrofit') {
                    this.tableData.push(baseCases[0])
                }

                var filterData = r.data.chartData.filter((element, index, array) => {
                    if(element[14] > value[0] && element[14] < value[1]) {
                        return true
                    }
                 })
                this.chartOptions.series = filterData.map(function (set, i) {
                    return {
                    name: '',
                    data: set,
                    shadow: false
                    };
                })
            })
            this.$refs.chart.chart.hideLoading()
        },
        xAxisAfterChange(value){
            this.$refs.chart.chart.showLoading();
            apiPareto.GetAllDesignSpace(this.selectedFormData).then(r => {
                var baseCases = r.data.paretoSolutionsOutput.filter(x=> x.caseNo === 147457 || x.caseNo === 147458 || x.caseNo === 147459)
                this.tableData = r.data.paretoSolutionsOutput.filter(x => x.primaryEnergyConsumption > value[0] && x.primaryEnergyConsumption < value[1])
                this.tableData.shift()
                if(this.selectedFormData.selectedObjective === 'retrofit') {
                    this.tableData.push(baseCases[0])
                }

                var filterData = r.data.chartData.filter((element, index, array) => {
                    if(element[13] > value[0] && element[13] < value[1]) {
                        return true
                    }
                 })
                this.chartOptions.series = filterData.map(function (set, i) {
                    return {
                    name: '',
                    data: set,
                    shadow: false
                    };
                })
                this.$refs.chart.chart.hideLoading()
                this.$refs.chart.chart.redraw()

            })
        },
        pointSelection(values, e) {
            apiPareto.GetAllDesignSpace(this.selectedFormData).then(r => {
                if (values.length > 0) {
                    // var baseCases = r.data.paretoSolutionsOutput.filter(x=> x.caseNo === 147457 || x.caseNo === 147458 || x.caseNo === 147459)
                    this.tableData = r.data.paretoSolutionsOutput.filter(function(item) {
                        for (var key in values) {
                            if (values[key].value === 'AAC') {
                                values[key].value = 'Aerated Concrete Block';
                            } else if(values[key].value === 'GBB') {
                                values[key].value = 'Gaz Beton Blok';
                            }

                            if (values[key].value === 'HCB') {
                                values[key].value = 'Horizontal Coring Brick';
                            } else if(values[key].value === 'YDT'){
                                values[key].value = 'Yatay Delikli Tuğla';
                            }

                            if (values[key].value === 'SW') {
                                values[key].value = localStorage.getItem('lang') === 'en' ?  'Stone Wool (SW)' : 'Taş Yünü'
                            }

                            if (values[key].value === 'XPS') {
                                values[key].value = localStorage.getItem('lang') === 'en' ?  'Extruded Polystyrene (XPS)' : 'Ekstrüde Polistiren';
                            }

                            if (values[key].value === 'G0') {
                                values[key].value = 'Double Glazing (4+12air+4mm)';
                            } else if(values[key].value === 'C0') {
                                values[key].value =  'Çift Cam (4+12hava+4mm)'
                            }

                            if (values[key].value === 'G1') {
                                values[key].value = 'Double Glazing (4+12air+4mm), Low-E (heat control)';
                            } else if(values[key].value === 'C1') {
                                values[key].value = 'Çift Cam (4+12hava+4mm), Low-E (ısı kontrol)'
                            }

                            if (values[key].value === 'G2') {
                                values[key].value = 'Double Glazing (4+12argon+4mm), Low-E (heatcontrol)';
                            } else if(values[key].value === 'C2') {
                                values[key].value = 'Çift Cam (4+12argon+4mm), Low-E (ısı kontrol)';
                            }


                            if (values[key].value === 'G3') {
                                values[key].value =  'Double Glazing (4+12argon+4mm), Low-E (heat-solar control)';
                            } else if (values[key].value === 'C3') {
                                values[key].value =  'Çift Cam (4+12argon+4mm), Low-E (ısı-güneş kontrol)';
                            }

                            if (values[key].value === 'G4') {
                                values[key].value =  'Triple Glazing (4+16argon+4+16argon+4mm), Low-E (heat-solar control)';
                            } else if(values[key].value === 'C4') {
                                values[key].value =  'Üçlü Cam (4+16argon+4+16argon+4mm), Low-E (ısı-güneş kontrol)';
                            }

                            if (values[key].value === 'FSCE') {
                                values[key].value = 'Fixed Solar Control Element (overhangs (South), overhangs and fins (East, West))';
                            } else if(values[key].value === 'SGKE') {
                                values[key].value = 'Sabit Güneş Kontrol Elemanı (çıkmalar (Güney), çıkma ve yan çıkıntılar (Doğu ve Batı))';

                            }

                            if (values[key].value === 'EVB') {
                                values[key].value = 'External Venetian Blinds (South, East, West)';
                            } else if (values[key].value === 'DJS') {
                                values[key].value = 'Dış Jaluzi Sistemi (Güney, Doğu, Batı)';
                            }

                            if (values[key].value === 'n/a') {
                                values[key].value ='Not Available';
                            } else if (values[key].value === 'm/b') {
                                values[key].value = 'Mevcut Değil';
                            }

                            if (values[key].value === '0.95') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Radiator System,η:0.95' : 'Radyatör Sistem,η:0.95';
                            }
                            if (values[key].value === '0.86') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Radiator System,η:0.86' : 'Radyatör Sistem,η:0.86';

                            }
                            if (values[key].value === '0.80') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Radiator System,η:0.80' : 'Radyatör Sistem,η:0.80';
                            }

                            if (values[key].value === '8.50') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Split Air Conditioner, SEER:8.50' : 'Split Klima, SEER:8.50';
                            }

                            if (values[key].value === '5.80') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Split Air Conditioner, SEER:5.80' : 'Split Klima, SEER:5.80';

                            }

                            if (values[key].value === '4.20') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Split Air Conditioner, SEER:4.20' : 'Split Klima, SEER:4.20';
                            }


                            if (values[key].value === 'VRF') {
                                values[key].value = 'Variable Refrigerant Flow System';
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Variable Refrigerant Flow System' : 'Değişken Soğutucu Akışkan Debili Sistem';

                            }

                            if (values[key].value === 'ASHP') {
                                values[key].value = 'Air Source Heat Pump (air-to-water)';
                            } else if (values[key].value === 'HKIP') {
                                values[key].value = 'Hava Kaynaklı Isı Pompası (havadan suya)';
                            }


                            if (values[key].value === '2.41') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Hot Water Boiler, η:2.41' : 'Sıcak Su Kazanı, η:2.41';

                            }

                            if (values[key].value === '0.86') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Stand Alone Water Heater, η:0.86' : 'Bireysel Su Isıtıcı, η:0.86';

                            }

                            if (values[key].value === '0.80') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Stand Alone Water Heater, η:0.80' : 'Bireysel Su Isıtıcı, η:0.80';
                            }

                            if (values[key].value === '10.5kWp') {
                                values[key].value = localStorage.getItem('lang') === 'en' ? 'Rooftop PV System (10.5kW)' : 'Çatı Entegre PV Sistem (10.5kW)';
                            }


                            if (item.exteriorWall === values[key].value || item.exteriorWallThermalInsulationType === values[key].value
                            || item.exteriorWallThermalInsulationThickness === values[key].value || item.roofThermalInsulationType === values[key].value || item.roofThermalInsulationThickness === values[key].value ||
                            item.groundFloorThermalInsulationThickness === values[key].value || item.glazingType === values[key].value || (item.solarContolElements === values[key].value && values[key].index === 7) || (item.heatingSystem === values[key].value && values[key].index === 8) || (item.coolingSystem === values[key].value && values[key].index === 9) || (item.heatingCoolingSystem === values[key].value && values[key].index === 10) || item.domesticHotWaterSystem === values[key].value || (item.photovoltaicSystem === values[key].value && values[key].index === 12) || item.primaryEnergyConsumption.toString() === values[key].value || item.lifeCycleCostTL.toString() === values[key].value)
                                return true;
                        }
                        return false;
                    });
                    // if (baseCases[0] !== undefined) {
                    //     this.tableData.push(baseCases[0])
                    // }
                }
                else {
                    // this.loadPage()
                }
            })
        },
        loadPage(selectedFormData) {
            this.$nextTick(function () {
                apiPareto.GetAllDesignSpace(selectedFormData).then(r => {
                    this.chartOptions.series = r.data.chartData.map(function (set, i) {
                        return {
                        name: '',
                        data: set,
                        shadow: false
                        };
                    })
                    this.tableData = r.data.paretoSolutionsOutput
                    this.YAxisMin = r.data.yAxisMin
                    this.YAxisMax = r.data.yAxisMax
                    this.XAxisMin = r.data.xAxisMin
                    this.XAxisMax = r.data.xAxisMax
                    this.loading = false;
                })
            })
        }
    
    },
    mounted() {
        var retrievedObject = localStorage.getItem('form');
        this.selectedFormData = JSON.parse(retrievedObject)
        this.loadPage(this.selectedFormData)
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRows = selectedRows
                }
            };
        }
    }
}
</script>
<style>
#starter {
  min-height: calc(100vh - 76px);
}

/* .highcharts-figure, .highcharts-data-table table {
  min-width: 320px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
font-family: Verdana, sans-serif;
border-collapse: collapse;
border: 1px solid #EBEBEB;
margin: 10px auto;
text-align: center;
width: 100%;
max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
} */

/* .highcharts-series-hover path {
  stroke: rgb(255, 66, 66);
  stroke-width: 2px;
} */
.highcharts-grid-line {
    opacity: 0 !important;
}

</style>